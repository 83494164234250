/**
 * Renders an generic dropdown
 * @returns {JSX.Element} The basic dropdown.
 * @param {any} props given by caller
 */

import { useState, useEffect } from 'react';
import URLS from 'Components/Lib/Utils/URLs.API';
import loadJsonData from '../Utils/loadJsonData';
import LocalStorage from 'Helpers/MFXLocalStorage';
import Select from 'react-select';
import { useSearchParams } from 'react-router-dom';

const Dropdown = props => {
    const [searchParams] = useSearchParams();
    const customerParam = searchParams.get('customer');
    const [dropdownOptions, setDropdownOptions] = useState(props.designData?.Options || null);
    const [defaultData, setDefaultData] = useState({ label: '', value: '' });
    const [missingValue, setMissingValue] = useState(null);

    let dropdownID = !isNaN(props.designData.Label) ? window.Dictionary.getTranslation(props.designData.Label) : props.designData.Label;
    dropdownID = 'dropdown-' + dropdownID?.replace(/\s/g, '-').toLowerCase();
    let dropdownKey = '';
    useEffect(() => {
        if (!props.designData?.Options) {
            let customerId = '';

            if (props.designData.DropdownName) dropdownKey = props.designData.DropdownName;
            else {
                dropdownKey = props.designData.Key.split('-');
                dropdownKey = dropdownKey[dropdownKey.length - 1];
            }
            if (dropdownKey === 'ContactPerson') {
                if (customerParam) customerId = '&ReferenceIndex=' + customerParam;
                else if (props.customerId) {
                    customerId = '&ReferenceIndex=' + props.customerId;
                } else {
                    let customer = LocalStorage.getTabData();
                    customerId = '&ReferenceIndex=' + customer?.primaryId;
                }
            }
            loadJsonData('/' + LocalStorage?.getConnectionKey() + URLS.GetDropdownValues + dropdownKey + customerId).then(data => {
                if (data.Meta.Success && data.Data) {
                    var valueFound = !props.customerData;
                    data.Data.map(d => {
                        return { Name: d.Name?.trim(), Value: d.Value?.trim() };
                    }).forEach(key => {
                        if ((key && props.customerData?.toString() === key?.Value) || (key && dropdownKey === 'PersonInChargeName' && (props.customerData?.toString() === key?.Name || props.customerData?.toString().replaceAll(',', '')?.toString() === key?.Name))) {
                            setDefaultData({ label: key.Name, value: key.Value });
                            valueFound = true;
                        }
                        if (!props.customerData && props.designData.DefaultValue /*&& props.designData?.Conditions?.length <= 0*/ && props.designData.DefaultValue?.toString() === key?.Value?.toString()) {
                            if (!props.isFilter) {
                                setDefaultData({ label: key.Name, value: key.Value });
                                if (props.onChangeHandler) props.onChangeHandler({ newValue: dropdownKey === 'PersonInChargeID' ? { label: key.Name, value: key.Value } : key.Value, designData: props.designData });
                            }
                            valueFound = true;
                        }
                    });

                    if (valueFound) {
                        setDropdownOptions([
                            { Name: '', Value: '' },
                            ...data.Data.map(d => {
                                return { Name: d.Name?.trim(), Value: d.Value?.trim() };
                            }),
                        ]);
                    } else {
                        setDropdownOptions([
                            { Name: '', Value: '' },
                            { Name: '!!!' + props.customerData?.toString() + '!!!', Value: '!!!' + props.customerData?.toString() + '!!!' },
                            ...data.Data.map(d => {
                                return { Name: d.Name?.trim(), Value: d.Value?.trim() };
                            }),
                        ]);
                        setDefaultData({ label: '!!!' + props.customerData?.toString() + '!!!', value: '!!!' + props.customerData?.toString() + '!!!' });
                        setMissingValue('!!!' + props.customerData?.toString() + '!!!');
                    }
                } else {
                    window.logger.error('No DropdownData ' + dropdownKey); // ERROR MESSAGE
                }
            });
        }
    }, [URLS]);

    useEffect(() => {
        dropdownOptions?.forEach(key => {
            if ((key && props.customerData?.toString() === key?.Value) || (key && dropdownKey === 'PersonInChargeName' && props.customerData?.toString() === key?.Name)) {
                setDefaultData({ label: key.Name, value: key.Value });
            }
        });
    }, [props.customerData, dropdownOptions]);

    const handleOnChange = event => {
        var dropdownKey = '';
        if (props.designData.DropdownName) dropdownKey = props.designData.DropdownName;
        else dropdownKey = props.designData.Key.split('-').pop();
        if (props.onChangeHandler) props.onChangeHandler({ newValue: dropdownKey === 'PersonInChargeID' ? { label: event.label, value: event.value } : event.value, designData: props.designData });
        setDefaultData({ label: event.label, value: event.value });
    };
    const handleKeyDown = event => {
        if (event.key === 'Enter' && props.onEnterPressed && defaultData.label && defaultData.value) {
            props.onEnterPressed();
        }
    };
    return (
        <div className={`mfx-dropdown required-${props.designData.Required ? props.designData.Required : false}`}>
            {' '}
            {/* Class because react-select has no reaquired field */}
            {props.designData.Label && (
                <label htmlFor={dropdownID}>
                    {!isNaN(props.designData.Label) ? window.Dictionary.getTranslation(props.designData.Label) : props.designData.Label}
                    {props.designData.Required && '*'}
                </label>
            )}
            <div className="mfx-react-select">
                <Select
                    menuPosition={'fixed'}
                    maxMenuHeight={150}
                    id={dropdownID}
                    name={props.designData.Key}
                    onChange={handleOnChange}
                    options={dropdownOptions?.map(option => {
                        return { label: option.Name, value: option.Value };
                    })}
                    onKeyDown={handleKeyDown}
                    value={props.customerData ? (typeof props.customerData === 'object' && props.customerData.value && props.customerData.label ? { label: props.customerData.label, value: props.customerData.value } : defaultData) : defaultData}
                    isDisabled={props.disableMode || Boolean(props.designData.ReadOnly)}
                    isOptionDisabled={option => {
                        return option.value === missingValue && option.label === missingValue;
                    }}
                />
            </div>
        </div>
    );
};

export default Dropdown;
