import LocalStorage from '../../../Helpers/MFXLocalStorage';

/**
 * Renders the New Tab Button
 * @params {props} 
 * @returns {JSX.Element} The NewTab.
 */

 const NewTab = (props) => {
    const curTabs = LocalStorage.getDBDataAsString('keys');

    return(
       <>
        {curTabs?.indexOf(props.type + '_' + props.idx) > 0 &&            
                <button className='mfx-addtab-button center text-icon dark button-border' 
                onClick={() =>  LocalStorage.removeTab(props.type + '_' + props.idx)}>
                    <i className="fas fa-minus"></i><span>{window.Dictionary.getTranslation(14266)}</span> 
                </button>
        }{curTabs?.indexOf(props.type + '_' + props.idx) === -1 &&
                <button className='mfx-addtab-button center text-icon button-border' id={'open-tab-' + props.idx} onClick={() => props.onAddNewTab(props.data, props.idx, props.type)}>
                    <i className="icon-add bold"></i><span>{window.Dictionary.getTranslation(14268)}</span>
                </button>
        }
       </>
    );

};

export default NewTab;