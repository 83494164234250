import PLXLocalStorage from './MFXLocalStorage';
import { To } from 'react-router-dom';

export default function localStorageLink(to: string | To) {
  
  if(!to) return '#';
  const connectionKey = PLXLocalStorage.getDBData().prefix || 'NO_SESSION';
  const separator = to.toString().startsWith('/') ? '' : '/';
  return `/designer/${connectionKey}${separator}${to}`;
}
