import { BaseControl, BaseElement, BaseElementGroup, ViewElement } from 'Components/Lib/Common/Interfaces/IViewProperties';
import Text from 'Components/Lib/Elements/Text';
import Button from 'Components/Lib/Elements/Button';
import Checkbox from 'Components/Lib/Elements/Checkbox';
import Currency from 'Components/Lib/Elements/Currency';
import Dropdown from 'Components/Lib/Elements/Dropdown';
import Headline from 'Components/Lib/Elements/Headline';
import InputElement from 'Components/Lib/Elements/InputElement';
import TextareaElement from 'Components/Lib/Elements/TextareaElement';
import Quicklink from 'Components/Lib/Elements/Quicklink';
import SmallHeadline from '../Elements/SmallHeadline';
import MainHeadline from '../Elements/MainHeadline';
import LinkIcon from '../Elements/LinkIcon';
import Anchor from '../Elements/Anchor';
import HiddenInput from '../Elements/HiddenInput';
import VoiceRecorder from '../Elements/VoiceRecorder';
import ListInputElement from '../Elements/ListInputElement';
import TableText from '../Elements/TableText';
import DoubleInputElement from '../Elements/DoubleInputElement';
import Profiling from '../Elements/Profiling';
import TimeRecordingType from '../Elements/TimeRecordingType';
import Upload from '../Elements/Upload';
import Download from '../Elements/Download';
import Explorer from '../Elements/Explorer';

function MapControl(props: { element: unknown /*aus Settings-Editor*/; data: unknown }) {
    return <>TBD: MapControl {props}</>;
}

interface IGenericViewElementProperties {
    designData?: ViewElement;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    data?: any;
    disableMode?: boolean;
    customData?: string | boolean | number | any;
    index?: string | number;
    noLabel?: boolean;
    onChangeGenericElement?: any;
    underlined?: boolean;
    viewName?: string;
    noForm?: boolean;
    isRecording?: boolean;
    customerId?: number;
    setRecording?(val: any): any;
    recordHandler?(): any;
}

export default function GenericElement(props: IGenericViewElementProperties): JSX.Element {
    // TODO: remove any
    function getValueFromData(key: any): string | undefined {
        if (key === undefined) return undefined;
        let data = props.customData;
        let keys = key.split('-');
        if (keys[0] === 'Data') keys = keys.slice(1);
        keys.forEach((k: any) => {
            data = data[k];
        });
        return data;
    }
    let elementValue = props.customData ?? (props.designData as BaseElement).Value ?? (props.designData as any).DefaultValue;
    if (typeof props.designData === 'object') {
        if (props.noForm && props.designData.Elementtype === 'input') (props.designData.Elementtype as any) = 'listinput';
        switch (props.designData.Elementtype || props.data) {
            case 'button':
                return <Button value={elementValue} designData={props.designData} />;
            case 'checkbox':
                return <Checkbox customerValue={props.customData} designData={props.designData} disableMode={props.disableMode} onChangeHandler={(valueObject: object) => props.onChangeGenericElement(valueObject)} />;
            case 'currency':
                return <Currency viewName={props.viewName} designData={props.designData} disableMode={props.disableMode} customerData={props.customData} uniqueIndex={props.index} onChangeHandler={(valueObject: object) => props.onChangeGenericElement(valueObject)} noLabel={props.noLabel || false} />;
            case 'dropdown':
                return <Dropdown designData={props.designData} customerData={props.customData} disableMode={props.disableMode} onChangeHandler={(valueObject: object) => props.onChangeGenericElement(valueObject)} customerId={props.customerId} />;
            case 'headline':
                return <Headline designData={props.designData} customerData={props.customData} />;
            case 'smallheadline':
                return <SmallHeadline value={elementValue} designData={props.designData} />;
            case 'mainheadline':
                return <MainHeadline designData={props.designData} customerData={props.customData} />;
            case 'input':
                return (
                    <InputElement
                        viewName={props.viewName}
                        designData={props.designData}
                        disableMode={props.disableMode}
                        customerData={props.customData}
                        uniqueIndex={props.index}
                        onChangeHandler={(valueObject: object) => props.onChangeGenericElement(valueObject)}
                        noLabel={props.noLabel || false}
                        underlined={props.underlined}
                    />
                );
            case 'doubleinput':
                return (
                    <DoubleInputElement
                        viewName={props.viewName}
                        designData={props.designData}
                        disableMode={props.disableMode}
                        customerData={props.customData}
                        uniqueIndex={props.index}
                        onChangeHandler={(valueObject: object) => props.onChangeGenericElement(valueObject)}
                        noLabel={props.noLabel || false}
                        underlined={props.underlined}
                    />
                );
            case 'listinput':
                return <ListInputElement viewName={props.viewName} designData={props.designData} customerData={props.customData} uniqueIndex={props.index} />;
            case 'hiddeninput':
                return <HiddenInput designData={props.designData} disableMode={props.disableMode} customerData={props.customData} uniqueIndex={props.index} onChangeInput={(valueObject: object) => props.onChangeGenericElement(valueObject)} noLabel={props.noLabel || false} />;
            case 'text':
                return <Text value={elementValue} designData={props.designData} />;
            case 'tabletext':
                return <TableText value={elementValue} designData={props.designData} />;
            case 'textarea':
                return (
                    <TextareaElement
                        designData={props.designData}
                        disableMode={props.disableMode}
                        customerData={props.customData}
                        uniqueIndex={props.index}
                        onChangeHandler={(valueObject: object) => props.onChangeGenericElement(valueObject)}
                        noLabel={props.noLabel || false}
                        underlined={props.underlined}
                    />
                );
            case 'quicklink':
                return <Quicklink value={elementValue} designData={props.designData} onClickHandler={(type: string) => props.onChangeGenericElement(type)} />;
            case 'link':
                return <Anchor value={elementValue} designData={props.designData} />;
            case 'linkicon':
                return <LinkIcon value={elementValue} designData={props.designData} />;
            case 'voicetotext':
                return (
                    <VoiceRecorder
                        designData={props.designData}
                        disableMode={props.disableMode}
                        isRecording={props.isRecording}
                        setRecording={props.setRecording}
                        customerData={props.customData}
                        uniqueIndex={props.index}
                        onChangeHandler={(valueObject: object) => props.onChangeGenericElement(valueObject)}
                        noLabel={props.noLabel || false}></VoiceRecorder>
                );
            case 'elementgroup': {
                const group = props.designData as BaseElementGroup;
                return (
                    <div className={`mfx-grid-container ${group.Classes}`}>
                        <>
                            {group.Data.map((el, elIndex) => {
                                return (
                                    <div key={`group-${elIndex}`} className={`mfx-elementgroup mfx-elementgroup-type-${group.Grouptype} mfx-col-${group.Groupcol}`}>
                                        {el.map((subel, gindex) => {
                                            if ((subel as any).Key) {
                                                subel.Value = getValueFromData((subel as any).Key);
                                            }
                                            if (typeof props.customData !== 'object') {
                                                return <GenericElement key={gindex} designData={subel} data={props.data} disableMode={props.disableMode} customData={props.customData} onChangeGenericElement={props.onChangeGenericElement} />;
                                            } else return <GenericElement key={gindex} designData={subel} data={props.data} disableMode={props.disableMode} customData={props.customData[(subel as any).Key]} onChangeGenericElement={props.onChangeGenericElement} />;
                                        })}
                                    </div>
                                );
                            })}
                        </>
                    </div>
                );
            }
            case 'empty':
                return <div></div>;
            case 'MapControl':
                return <MapControl element={(props.designData as BaseControl).Data} data={props.data} />;
            case 'StockControl':
                return <>TBD: StockControl in renderViewElement</>;
            case 'profiling':
                return <Profiling customData={props.customData} designData={props.designData} disableMode={props.disableMode} onChangeHandler={(valueObject: object) => props.onChangeGenericElement(valueObject)} />;
            case 'timerecordingtype':
                return <TimeRecordingType customData={props.customData} designData={props.designData} disableMode={props.disableMode} onChangeHandler={(valueObject: object) => props.onChangeGenericElement(valueObject)} />;
            case 'upload':
                return <Upload customData={props.customData} designData={props.designData} disableMode={props.disableMode} onChangeHandler={(valueObject: object) => props.onChangeGenericElement(valueObject)} />;
            case 'download':
                return <Download customData={props.customData} designData={props.designData} disableMode={props.disableMode} onChangeHandler={(valueObject: object) => props.onChangeGenericElement(valueObject)} />;
            case 'explorer':
                return <Explorer customData={props.customData} designData={props.designData} disableMode={props.disableMode} onChangeHandler={(valueObject: object) => props.onChangeGenericElement(valueObject)}/>;
        }
    }

    return <>Element konnte nicht gefunden werden</>;
}
