const TimeRecordingType = props => {
    console.log(props);

    return (
        <div className="mfx-detailview-formelements-full-width" style={{ paddingBottom: '10px', borderBottom: '1px solid black' }}>
            {
                //<label htmlFor={props.designData?.Key + '-div'}>{window.Dictionary.getTranslation(props.designData?.Label)}:</label>
            }
            <div id={props.designData?.Key + '-div'} className="mfx-detailview-formelements-nested">
                {props.designData?.Options?.map(type => {
                    return (
                        <div>
                            <button
                                style={{border: type.Value === props.customData ? '2px solid blue':''}}
                                className="icon-button"
                                onClick={e => {
                                    e.preventDefault();
                                    if (props.disableMode) return;
                                    props.onChangeHandler({ newValue: type.Value, designData: props.designData });
                                }}>
                                <i className={type.Icon} />
                                <span>{type.Label}</span>
                            </button>
                        </div>
                    );
                })}
                {!props.designData?.Options && <h1>Typesettings in Designer missing</h1>}
            </div>
        </div>
    );
};

export default TimeRecordingType;
