import { useEffect, useState } from 'react';

import Loading from 'Components/Lib/Elements/Loading';
import SmallHeadline from 'Components/Lib/Elements/SmallHeadline';
import loadJsonData from '../Utils/loadJsonData';
import LocalStorage from 'Helpers/MFXLocalStorage';
import URLS from '../Utils/URLs.API';
import InputElement from '../Elements/InputElement';

interface IListItems {
    Time?: string;
    ListItemName?: string;
    Action?: string;
    Tasknumber?: string | number;
    Taskdesc?: string;
}

/**
 * Renderes a List
 * @param {any} props Properties given by the caller
 * @returns {JSX.Element} Rendered Element.
 */
function CustomerTurnoverView(props: any): JSX.Element {
    const [data, setData] = useState([] as any);
    const [loading, setLoading] = useState(true);
    //TODO
    //load todays Appointments from Outlook
    //from - props.Listtype, today
    useEffect(() => {
        setLoading(true);
        console.log(LocalStorage.getCurrentTabUrl());
        loadJsonData('/' + LocalStorage?.getConnectionKey() + URLS.GetTurnover + '?CustomerNumber=' + LocalStorage.getCurrentTabUrl()?.split('_').slice(-1)).then(data => {
            if (data.Meta.Success && data.Data) {
                setData(data.Data as any);
            }
            setLoading(false);
        });
    }, []);
    console.log(data);
    return (
        <div className="mfx-table-container">
            <table className="mfx-table">
                {loading && <Loading />}
                {!loading && data?.length > 0 && (
                    <>
                        <tr>
                            <th>{window.Dictionary.getTranslation(3698)}</th>
                            <th>{window.Dictionary.getTranslation(358)}</th>
                            <th>{window.Dictionary.getTranslation(1866)}</th>
                            <th>{window.Dictionary.getTranslation(1867)}</th>
                        </tr>
                        {data.map((d, id) => {
                            return (
                                <tr>
                                    <td>{d.Year}</td>
                                    <td>{(Math.round(d.TurnoverAmount * 100) / 100).toFixed(2)}</td>
                                    <td>{(Math.round(d.Db * 100) / 100).toFixed(2)}</td>
                                    <td>{(Math.round(d.Dbp * 100) / 100).toFixed(2)}</td>
                                </tr>
                            );
                        })}
                    </>
                )}
            </table>
        </div>
    );
}

export default CustomerTurnoverView;
