/* eslint-disable jsx-a11y/label-has-associated-control */
import { useEffect, useState } from 'react';

const Upload = props => {
    const [firstLoad, setFirstLoad] = useState(false);
    const [fileData, setFileData] = useState([]);

    useEffect(() => {
        loadData();
    }, []);

    useEffect(() => {
        if (firstLoad) loadData();
    }, [props?.customData?.Assignment]);

    function loadData() {}
    function onChange(event) {
        console.log(event.target.files);
        const fileReader = new FileReader();
        fileReader.readAsDataURL(event.target.files[0]);
        fileReader.onload = e => {
            console.log(e);
            setFileData(prev => {
                if (e?.target?.result) {
                    var x = e?.target?.result.split(';base64,');
                    props.onChangeHandler({ newValue: [...prev, { data: x[1] ?? '', name: event.target.files[0].name }], designData: props.designData });
                    return [...prev, { data: x[1] ?? '', name: event.target.files[0].name }];
                }
            });
        };
        /*var newData = JSON.parse(JSON.stringify(defaultProfiling));
        if (newData[inx]) newData[inx].Value = newValue;
        setDefaultProfiling(newData);
        props.onChangeHandler({ newValue: newData, designData: props.designData });*/
    }

    return (
        <div className="mfx-detailview-formelements-full-width">
            <label htmlFor={props.designData?.Key + '-div'}>{window.Dictionary.getTranslation(props.designData?.Label)}</label>
            <div id={props.designData?.Key + '-div'} className="mfx-detailview-formelements-nested-2-columns">
                <div className="custom-file-upload">
                    <label for="file-upload" class="custom-file-upload">
                        <i className="icon-close"></i>
                        {window.Dictionary.getTranslation(16275)}
                    </label>
                    <input id="file-upload" type="file" onChange={e => onChange(e)} />
                </div>
                <div className="custom-file-upload">
                    <label for="camera" class="custom-file-upload">
                        <i className="icon-close"></i>
                        {window.Dictionary.getTranslation(16274)}
                    </label>
                    <input id="camera" type="file" accept="image/*" capture="camera" onChange={e => onChange(e)} />
                </div>
            </div>
            {fileData.map(file => {
                return <label>{file.name}</label>;
            })}
        </div>
    );
};

export default Upload;
