/**
 * Renders a Info Button and Text
 * Disappears after 5 Seconds
 * @returns {JSX.Element} Info.
 */

import { useState } from 'react';

const Info = (props) => {
    const [displayInfoText, setDisplayInfoText] = useState(false);


    function activateInfoText(e){
        let timer;

        if(!displayInfoText) { 
            timer = setTimeout(() => {
                setDisplayInfoText(false);
            }, 5000);
        }else {
            clearInterval(timer);
        }

        setDisplayInfoText(!displayInfoText);
    }

    
    return(
        <>
            <i title={props.text} className={`icon-info info-icon-style active-${displayInfoText}`} onClick={activateInfoText}></i>
            <p className={`info-text active-${displayInfoText}`} >{props.text}</p> 
        </>
    );

};

export default Info;