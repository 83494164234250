import { useState } from 'react';
import LocalStorage from '../../../../Helpers/MFXLocalStorage';
import Logo from '../../../../Styles/images/logo.png';
import { deleteCookie } from '../../../../Helpers/MFXCookie';
import { Link } from 'react-router-dom';
import deleteData from 'Components/Lib/Utils/deleteData';
import URLS from 'Components/Lib/Utils/URLs.API';
import SessionStorage from 'Helpers/MFXSessionStorage';
import WikiInfo from 'Components/Lib/Elements/WikiInfo';

const Topheader = () => {
    const [submenuOpen, setSubmenuOpen] = useState(false);

    return (
        <div id="mfx-topheader">
            <div id="mfx-logocontainer">
                <img src={Logo} id="logo" alt="Logo" />
                <h4>CRM DESIGNER</h4>
            </div>
            <div id="mfx-infocontainer">
                <nav id="help">
                    <WikiInfo></WikiInfo>
                </nav>
                <nav id="user">
                    <ul className="menu">
                        <li>
                            <i className="icon-user" onClick={() => setSubmenuOpen(!submenuOpen)}></i>
                            <ul className={`sub-menu ${submenuOpen ? 'open' : ''}`}>
                                <li>
                                    <button
                                        className="no-styling"
                                        id="header-logout"
                                        onClick={() => {
                                            deleteData('/' + LocalStorage?.getConnectionKey() + URLS.Logout)
                                                .then(data => {
                                                    if (data.Meta.Success) {
                                                        LocalStorage.removeAllLocals();
                                                        SessionStorage.removeAllStorage();
                                                        SessionStorage.createSession('Logout', window.location.href);
                                                        deleteCookie('MFX_Login');
                                                    }
                                                })
                                                .catch(e => {
                                                    console.log(e);
                                                });
                                        }}>
                                        {window.Dictionary?.getTranslation(3317)}
                                    </button>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </nav>
            </div>
        </div>
    );
};

export default Topheader;
