import loadJsonData from '../Utils/loadJsonData';
import LocalStorage from 'Helpers/MFXLocalStorage';

const Download = props => {
    function download(e) {
        e.preventDefault();
        loadJsonData('/' + LocalStorage?.getConnectionKey() + props.designData.Type + '?Data.Number=' + props.customData.OfferNumber).then(data => {
            exportFile(data.Data.PdfData, 'OfferOrder_'+ props.customData.OfferNumber+ '.pdf');
        });
    }
    function exportFile(data, name) {
        const element = document.createElement('a');
        element.href = 'data:application/pdf;base64,' + data;
        element.download = name;
        document.body.appendChild(element);
        element.click();
    }
    return (
        <div>
            {props.designData.Label && <label>{window.Dictionary.getTranslation(props.designData.Label)}</label>}
            <button id={'pdf-download-' + props.customData.Number} onClick={download}>Download</button>
        </div>
    );
};

export default Download;
