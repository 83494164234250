/**
 * Renders only the Button
 * @returns {JSX.Element} The basic Button.
 */

import { useState } from 'react';
import Pagination from './Pagination';

const SqlTable = props => {
    const [activePage, setActivePage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [totalEntries, setTotalEntries] = useState(props.chart.Data.length);
    const [totalPages, setTotalPages] = useState(Math.ceil(props.chart.Data.length / 10));
    const activeHandler = (clickedActive) => {
        setActivePage(parseInt(clickedActive));
    };
    return (
        <>
            {props.chart.Data && props.chart.Data.length > 0 && (
                <>
                {!props.designer?.Settings?.IsListView && (
                    <Pagination
                        active={activePage}
                        size={totalPages}
                        entries={totalEntries}
                        pageSize={pageSize}
                        step={2}
                        onClickHandler={activeHandler}
                        onPageSizeChangeHandler={(size) => {
                            setPageSize(size);
                            setTotalPages(Math.ceil(props.chart.Data.length / size));
                        }}
                    />
                )}
                <div className="mfx-table-container no-overflow" >
                    <table className={'mfx-table'}>
                        <tbody>
                            <tr className="mfx-table-row mfx-table-head">
                                {Object.entries(props.chart.Data[0]).map(data => {
                                    return <th className={'mfx-table-column-head'}>{data[0]}</th>;
                                })}
                            </tr>
                            {props.chart.Data.slice((activePage-1)*pageSize,pageSize * activePage).map(entries => {
                            return <tr className="mfx-table-row mfx-table-head">
                                {Object.entries(entries).map(data => {
                                    return <td className={'mfx-table-column-head'}>{data[1]}</td>;
                                })}
                            </tr>;
                            })}
                        </tbody>
                    </table>
                </div>
                </>
            )}
            {(!props.chart.Data || props.chart.Data.length === 0) && (
                <h1>No Data found.</h1>
            )}
        </>
    );
};

export default SqlTable;
