/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/label-has-associated-control */
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { viewActions } from '../../../../Helpers/Stores/viewStore';
import { RootState } from '../GenericViewEditor';
import ElementTypeOptions from '../Elements/ElementTypeOptions';
import elements from '../Common/AllElements';
import IconMenu from '../Layouts/IconMenu';

interface IGenericPageMenuProps {
    data: any;
    index: any;
    onChangeHandler(settings: object): void;
    onSelectElement(key: any, e: any): void;
}

export default function ElementsMenu(props: IGenericPageMenuProps) {
    const dispatch = useDispatch();
    const curElement = useSelector((state: RootState) => (props?.index?.colKey >= 0 ? (state.viewData.CustomElements[props?.index?.groupKey] as any)?.Data[props?.index?.colKey][props?.index?.rowKey] : (state.viewData.CustomElements[props?.index?.groupKey] as any)));
    const topElement = (props.data as any).CustomElements[props?.index?.groupKey];
    const allData = useSelector((state: RootState) => state.viewData.Data[0]);
    const selectableData = getAllSelectKeys(allData);
    const [settingsSelected, setSettingsSelected] = useState({
        default: false,
        element: false,
        elementgroup: false,
        selectmode: true,
    });

    function getAllSelectKeys(data: any) {
        let x = [] as any;
        Object.entries(data).forEach((attributes: any, idxObj) => {
            if (typeof attributes[1] === 'object') {
                if (attributes[1].Designable && attributes[1].Key && attributes[1].Label) {
                    x.push(attributes[1]);
                } else x.push(...getAllSelectKeys(attributes[1]));
            }
        });
        return x;
    }

    useEffect(() => {
        if (props.index?.colKey >= 0 && !settingsSelected.element) {
            showSettings('element');
        } else if (props.index?.groupKey >= 0 && !props.index?.colKey && props.index?.colKey !== 0 && !settingsSelected.elementgroup) {
            showSettings('elementgroup');
        }
    }, [props.index]);

    function showSettings(type: string) {
        let newSettingsObject = { ...settingsSelected } as any;
        Object.keys(newSettingsObject).forEach(settings => {
            if (settings === type) newSettingsObject[type] = !newSettingsObject[type];
            else newSettingsObject[settings] = false;
        });

        setSettingsSelected(newSettingsObject);
    }
    function changeElement(value: any, target: string) {
        if(target === 'ElementType' && value === 'dropdown'){
            dispatch(viewActions.changeElement({ value: value, target: target, key: props.index }));
        }
        dispatch(viewActions.changeElement({ value: value, target: target, key: props.index }));
    }

    function changeElementGroup(value: any, target: string) {
        dispatch(viewActions.changeElementGroup({ value: value, target: target, key: props.index }));
        props.onSelectElement(null, { groupKey: props.index.groupKey });
    }
    function removeElementGroup() {
        dispatch(viewActions.removeElementGroup(props.index.groupKey));
        props.onSelectElement(null, null);
    }
    function changeIcon(data: any) {
        changeElementGroup(data.value, data.type);
    }

    function getSettingsByElementtype(settings: any) {
        if (!settings) return <></>;
        return (
            <>
                {settings.Headline && (
                    <>
                        <label>{window.Dictionary.getTranslation(14867)}</label>
                        <input type="text" value={topElement?.Headline} onChange={e => changeElementGroup(e.target.value, 'Headline')} />
                    </>
                )}
                {settings.Subheadline && (
                    <>
                        <label>{window.Dictionary.getTranslation(14868)}</label>
                        <input type="text" value={topElement?.Subheadline} onChange={e => changeElementGroup(e.target.value, 'Subheadline')} />
                    </>
                )}
                {settings.Key && (
                    <>
                        <div className="mfx-dropdown-element">
                            <label>{window.Dictionary.getTranslation(14870)}:</label>
                            <select value={curElement?.Key ? curElement.Key : ''} onChange={e => changeElement(e.target.value, 'Key')}>
                                <option value={''}></option>
                                {selectableData
                                    ?.sort((a: any, b: any) => (window.Dictionary.getTranslation(a.Label) > window.Dictionary.getTranslation(b.Label) ? 1 : -1))
                                    .map((e: any, idx: any) => {
                                        return (
                                            <option key={idx} value={e.Key}>
                                                {window.Dictionary.getTranslation(e.Label)}
                                            </option>
                                        );
                                    })}
                            </select>
                        </div>
                    </>
                )}
                {settings.Label && (
                    <>
                        <label>{window.Dictionary.getTranslation(14469)}:</label>
                        <input type="text" value={curElement?.Label} onChange={e => changeElement(e.target.value, 'Label')} />
                    </>
                )}
                {settings.DefaultValue && (
                    <>
                        <label>{window.Dictionary.getTranslation(5680)}:</label>
                        {curElement.Elementtype === 'checkbox' && <input type="checkbox" value={curElement?.DefaultValue} onChange={e => changeElement(e.target.checked, 'DefaultValue')} />}
                        {curElement.Elementtype === 'currency' && <input type="currency" value={curElement?.DefaultValue} onChange={e => changeElement(e.target.value, 'DefaultValue')} />}
                        {curElement.Elementtype === 'input' && <input type={curElement.Type ?? 'text'} value={curElement?.DefaultValue} onChange={e => changeElement(e.target.value, 'DefaultValue')} />}
                        {curElement.Elementtype !== 'input' && curElement.Elementtype !== 'checkbox' && curElement.Elementtype !== 'currency' && <input type="text" value={curElement?.DefaultValue} onChange={e => changeElement(e.target.value, 'DefaultValue')} />}
                    </>
                )}

                {settings.Href && (
                    <>
                        <label>{window.Dictionary.getTranslation(14871)}:</label>
                        <input type={'url'} value={curElement.Href} onChange={e => changeElement(e.target.value, 'Href')} />
                    </>
                )}
                {settings.Target && (
                    <>
                        <label>{window.Dictionary.getTranslation(14872)}:</label>
                        <input type={'text'} value={curElement.Target} onChange={e => changeElement(e.target.value, 'Target')} />
                    </>
                )}
                {settings.Placeholder && (
                    <>
                        <label>{window.Dictionary.getTranslation(689)}:</label>
                        <input type={'text'} defaultValue={curElement.Placeholder} onChange={e => changeElement(e.target.value, 'Placeholder')} />
                    </>
                )}
            </>
        );
        /*

                        <label>{window.Dictionary.getTranslation(14869)}</label>
                        <IconMenu curContainer={{ icon: topElement?.icon, iconColor: topElement?.iconColor }} changeIcon={changeIcon} />
        );*/
    }
    function selectElement(el: any) {
        dispatch(viewActions.selectElement(el));
    }

    return (
        <div id="mfx-generic-view-menu">
            <h2>{window.Dictionary.getTranslation(1109)}</h2>
            <div className="mfx-settings-accordion">
                <h3 className={settingsSelected.element ? 'active' : ''} onClick={() => showSettings('element')}>
                    {window.Dictionary.getTranslation(14874)}
                </h3>
                {settingsSelected.element && props.index?.colKey >= 0 && props.data.CustomElements[props.index.groupKey].Data[props.index.colKey][props.index.rowKey] && (
                    <>
                        <div className="mfx-dropdown-element">
                            <label> {window.Dictionary.getTranslation(14875)}:</label>
                            <select value={curElement?.Elementtype} onChange={e => changeElement(e.target.value, 'Elementtype')}>
                                <ElementTypeOptions onlyElementGroups={false}></ElementTypeOptions>
                            </select>
                        </div>
                        {(elements.find((e: any) => e.Elementtype === curElement?.Elementtype) as any)?.Types && (
                            <div className="mfx-dropdown-element">
                                <label> {window.Dictionary.getTranslation(53)}:</label>
                                <select value={curElement?.Type} onChange={e => changeElement(e.target.value, 'Type')}>
                                    {(elements.find((e: any) => e.Elementtype === curElement?.Elementtype) as any)?.Types?.map((type: any, idx: any) => {
                                        return (
                                            <option key={idx} value={type}>
                                                {type}
                                            </option>
                                        );
                                    })}
                                </select>
                            </div>
                        )}
                        {getSettingsByElementtype((elements.find((e: any) => e.Elementtype === curElement?.Elementtype) as any)?.Settings)}
                        <button className="mfx-button button-border" onClick={e => dispatch(viewActions.removeElement(props.index))}>{window.Dictionary.getTranslation(14880)}</button>
                    </>
                )}
                {settingsSelected.element && ((!props.index?.colKey && props.index?.colKey !== 0) || !props.data.CustomElements[props.index.groupKey].Data[props.index.colKey][props.index.rowKey]) && <label>{window.Dictionary.getTranslation(14882)}</label>}
            </div>
            <div className="mfx-settings-accordion">
                <h3 className={settingsSelected.elementgroup ? 'active' : ''} onClick={() => showSettings('elementgroup')}>
                    {window.Dictionary.getTranslation(14876)}
                </h3>
                {settingsSelected.elementgroup && props.index?.groupKey >= 0 && (
                    <>
                        <div className="mfx-dropdown-element">
                            <label> {window.Dictionary.getTranslation(6254)}:</label>
                            <select value={(props.data as any).CustomElements[props.index.groupKey ?? 0].Groupcol} onChange={e => changeElementGroup(e.target.value, 'Groupcol')}>
                                <option value={3}>4</option>
                                <option value={4}>3</option>
                                <option value={6}>2</option>
                                <option value={12}>1</option>
                            </select>
                        </div>
                        <div className="mfx-dropdown-element">
                            <label>{window.Dictionary.getTranslation(14875)}:</label>
                            <select value={topElement.Elementtype} onChange={e => changeElementGroup(e.target.value, 'Elementtype')}>
                                <ElementTypeOptions onlyElementGroups={true}></ElementTypeOptions>
                            </select>
                        </div>
                        {getSettingsByElementtype((elements.find((e: any) => e.Elementtype === curElement?.Elementtype) as any)?.Settings)}
                        <button className="mfx-button button-border" onClick={removeElementGroup}>{window.Dictionary.getTranslation(14877)}</button>
                    </>
                )}
                {settingsSelected.elementgroup && !props.index?.groupKey && props.index?.groupKey !== 0 && <label>{window.Dictionary.getTranslation(14878)}</label>}
            </div>
        </div>
    );
}
