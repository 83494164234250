import { useEffect, useState } from 'react';
import LocalStorage from '../../../../Helpers/MFXLocalStorage';
import loadJsonData from '../../Utils/jsonGet';
import loadJsonPost from '../../Utils/jsonPost';
import loadJsonPut from '../../Utils/jsonPut';
import URLS from '../../Utils/URLs.API';
import defaultMenu from '../../../../Data/Designer/clientmenu_default.json';
import customerMenu from '../../../../Data/Designer/clientmenu_customer.json';
import SavedModal from '../Elements/SavedModal';
import Checkbox from '../Elements/MenuCheckbox';

import { Provider, useDispatch, useSelector } from 'react-redux';
import store, { menuActions } from '../../../../Helpers/Stores/menuStore';
import GenericElement from '../Elements/GenericElements';

export function ClientMenus(props: any) {
    const menuData = useSelector((state: RootState) => state.menuData);
    const defaultMenuData = useSelector((state: RootState) => state.defaultMenuData);
    const dispatch = useDispatch();

    const [curPageId, setCurPageId] = useState(null as any);
    const [saveMessage, setSaveMessage] = useState(null as any);
    console.log(props);
    useEffect(() => {
        setDefaultMenu();
        loadData();
    }, [props.name]);

    const setDefaultMenu = () => {
        const menuType = props.name.indexOf('customer') >= 0 ? customerMenu : defaultMenu;
        dispatch(menuActions.init({ menuData: menuType, defaultMenuData: menuType }));
    };

    function loadData() {
        loadJsonData('/' + LocalStorage?.getConnectionKey() + URLS.GetBusinessProcess + 'CRM&PageLayout=' + props.name)
            .then((data: any) => {
                setCurPageId(data.Data.ID);

                if (data.Meta.Success && data.Data.Json) {
                    dispatch(menuActions.init({ menuData: data.Data.Json }));
                    //if (props.name === 'customer') setDefaultMenuData(customerMenu);
                }
            })
            .catch(e => {
                console.log('Default Json Data!');
            });
    }

    async function saveMenu() {
        //IF you save a new menu
        console.log(menuData);
        if (curPageId === null) {
            loadJsonPost('/' + LocalStorage?.getConnectionKey() + URLS.PostBusinessProcess, {
                CreateData: { BusinessProcessName: 'CRM', ConfigData: JSON.stringify(menuData), Pagename: 'menu', Pagelayout: props.name, Type: 1 },
            }).then(data => {
                if (data.Meta.Success) setSaveMessage({ message: 14445, success: true });
                else setSaveMessage({ message: 13656, success: false });
            });
        } else {
            loadJsonPut('/' + LocalStorage?.getConnectionKey() + URLS.PutBusinessProcess, {
                UpdateData: { BusinessProcessName: 'CRM', ConfigData: JSON.stringify(menuData), Pagename: 'menu', Pagelayout: props.name, ID: curPageId, Type: 1 },
            }).then(data => {
                if (data.Meta.Success) {
                    setSaveMessage({ message: 14445, success: true });
                } else setSaveMessage({ message: 13656, success: false });
            });
        }

        //setCurPage({ ...curPage, selected: false, saved: true, containerId: -1 });
    }

    /* --------------- DRAGABLE ---------------*/

    const [target, setTarget] = useState(null);
    const [shadow, setShadow] = useState(null);

    function dragStart(e: any, index: any) {
        setShadow(index);
    }
    function dragOver(e: any, index: any) {
        e.preventDefault();
        setTarget(index);
    }

    function dragEnd(e: any, index: any) {
        if (target !== null && target !== undefined && shadow !== null && target !== undefined) dispatch(menuActions.setOrder({ target: target, shadow: shadow }));
        else alert('Drag and Drop failed since the object it was dropped on was not part of the Grid');
    }

    return (
        <>
            <main id="mfx-generic-view-editor">
                {menuData && (
                    <>
                        <div className="mfx-preview">
                            <div id="mfx-generic-view-preview" className="mfx-gp-page">
                                <div className="mfx-view menu-view">
                                    <h1>
                                        {window.Dictionary.getTranslation(15226)} ({props.name})
                                    </h1>
                                    <div className={'mfx-gp-container'}>
                                        {menuData &&
                                            menuData.entries.map((key: any, idx: number) => {
                                                return (
                                                    <GenericElement
                                                        draggable={true}
                                                        onDragEnd={dragEnd}
                                                        onDragStart={dragStart}
                                                        onDragOver={dragOver}
                                                        index={idx}
                                                        disableMode={false}
                                                        onChangeHandler={newData => dispatch(menuActions.addCustomValue(newData))}
                                                        customData={key.customlabel ?? ''}
                                                        designData={{ Key: key.label, Name: key.label, Label: key.label, DefaultValue: key.customlabel ?? '', Elementtype: 'input', Type: 'text' }}
                                                    />
                                                );
                                            })}
                                    </div>
                                </div>
                                <div className="grid-common-buttons">
                                    <button type="button" className={'mfx-button mfx-button-type-icon'} onClick={setDefaultMenu}>
                                        <i className="icon-check"></i>
                                        <span>{window.Dictionary.getTranslation(14625)}</span>
                                    </button>
                                    <button type="button" className={'mfx-button mfx-button-type-icon'} onClick={loadData}>
                                        <i className="icon-close"></i>
                                        <span>{window.Dictionary.getTranslation(7496)}</span>
                                    </button>
                                    <button type="button" id="button-save" className={'mfx-button mfx-button-type-icon save-button-designer important'} onClick={saveMenu}>
                                        <i className="icon-save"></i>
                                        <span>{window.Dictionary.getTranslation(63)}</span>
                                    </button>
                                </div>
                            </div>
                            <div className="mfx-sidebar">
                                <h2>{window.Dictionary.getTranslation(1109)}</h2>

                                <div className="pl-element-selection">
                                    {defaultMenuData.entries.map((entry: any, idx: number) => {
                                        return (
                                            <Checkbox
                                                key={idx}
                                                designData={{ Label: entry.label, Key: idx, CheckedInDesigner: menuData.entries.find((item: any) => window.Dictionary.getTranslation(item.label) === window.Dictionary.getTranslation(entry.label)), ReadOnly: entry.label === 'Dashboard' ? true : false }}
                                                onChangeHandler={(settingsObject: object) => dispatch(menuActions.changeData({ ...settingsObject, link: entry.link }))}
                                            />
                                        );
                                    })}
                                </div>
                            </div>
                        </div>
                    </>
                )}

                {saveMessage && <SavedModal saveMessage={saveMessage} closeIcon={true} handleClose={() => setSaveMessage(null)} />}
            </main>
        </>
    );
}

export function ClientMenuProvider(props: any) {
    return (
        <Provider store={store}>
            <ClientMenus {...props} />
        </Provider>
    );
}

export type RootState = ReturnType<typeof store.getState>;
