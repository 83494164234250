import { useEffect, useState } from 'react';
import GenericElement from '../Utils/GenericElement';
import loadJsonData from '../Utils/loadJsonData';
import LocalStorage from '../../../Helpers/MFXLocalStorage';
import URLS from '../Utils/URLs.API';
import Loading from '../Elements/Loading';
import Editbutton from '../Elements/Editbutton';
import deleteData from '../Utils/deleteData';
import updateData from '../Utils/updateData';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Headline from '../Elements/MainHeadline';
import SavedModal from '../Elements/SavedModal';
import SmallHeadline from '../Elements/SmallHeadline';
import MainHeadline from '../Elements/MainHeadline';
import { getPropertiesPerType } from '../../../Helpers/getPropertiesPerType';
import ModalPopup from '../Common/ModalPopup';
import { AddViewProvider } from './AddView';
import Addbutton from '../Elements/Addbutton';
import ModalOverlay from '../Common/ModalOverlay';
import QuestionModal from '../Elements/QuestionModal';
import apiPost from '../Hooks/apiPost';
import MFXLocalStorage from '../../../Helpers/MFXLocalStorage';
import loadJsonPost from '../Utils/loadJsonPost';

/**
 * Renders a Table / List of all Tasks.
 * @returns {JSX.Element} Rendered component
 */

export default function GenericDetailView(props: any): JSX.Element {
    const navigate = useNavigate();
    const [mobile, setMobile] = useState(window.innerWidth <= window.mobileBreakPoint ? true : false);

    function handleResize() {
        if (window.innerWidth <= window.mobileBreakPoint) setMobile(true);
        else setMobile(false);
    }
    window.addEventListener('resize', handleResize);

    const [searchParams] = useSearchParams();
    let index = searchParams.get('index');
    const search = searchParams.get('search') ?? false;
    let type = props.ViewName.substring(0, props.ViewName.indexOf('Detail')).toLowerCase();
    let properties = getPropertiesPerType(type);
    if (type === 'process') index = searchParams.get('process');
    else if (type === 'customer') index = MFXLocalStorage?.getTabData()?.primaryId > 0 ? MFXLocalStorage?.getTabData()?.primaryId?.toString() :  searchParams.get('customer');
    const [recording, setRecording] = useState(false);
    const [viewData, setViewData] = useState(null as any);
    const [saveMessage, setSaveMessage] = useState(null as any);
    const [showQuestion, setShowQuestion] = useState(null as any);
    const [dataLoaded, setDataLoaded] = useState(false);
    const [disableMode, setDisableMode] = useState(true);
    const [showProcessFromTask, setShowProcessFromTask] = useState(false);
    const [showNewProcessFromTask, setShowNewProcessFromTask] = useState(false);
    const [headline, setHeadline] = useState(null as any);
    useEffect(() => {
        //loadDesign();
        loadData();
    }, [props, searchParams]);
    function loadData() {
        if (index)
            loadJsonData('/' + LocalStorage?.getConnectionKey() + properties?.getData + index)
                .then((detail: any) => {
                    if (detail.Data !== null) {
                        setViewData({ Data: detail.Data });
                        if (MFXLocalStorage.getData('OfferSigningMessage')) {
                            loadJsonData('/' + LocalStorage?.getConnectionKey() + URLS.GetSigningHistory + '?Data.DocumentNumber=' + detail.Data.OfferNumber + '&Data.DocumentType=Offer')
                                .then((data: any) => {
                                    if (data?.Data?.SigningHistory?.length > 0 && data?.Data?.SigningHistory[0]?.SignStatus === 0) {
                                        setSaveMessage({ success: true, message: 16376 });
                                    } else setSaveMessage({ success: false, message: 16377 });
                                    MFXLocalStorage.removeSession('OfferSigningMessage');
                                })
                                .catch(e => {
                                    setSaveMessage({ success: false, message: 16379 });
                                });
                        }
                        loadDesign(props.LayoutName ?? 'default');

                        if (type === 'task') setHeadline({ processNumber: detail.Data.Process.Number, taskPosition: detail.Data.PositionNumber });
                    }
                })
                .catch(e => {});
    }

    function loadDesign(layoutName = 'default') {
        loadJsonData('/' + LocalStorage?.getConnectionKey() + URLS.ViewLayouts + '?ViewName=' + properties?.detailView + '&LayoutName=' + layoutName)
            .then((data: any) => {
                setViewData(prev => {
                    return { ...prev, DesignData: data.Data.Json };
                });
                setDataLoaded(true);
            })
            .catch(e => {});
    }
    function changeData(valueObject) {
        setSaveMessage(null);
        setValue(viewData.Data, valueObject.designData.Key.split('-'), valueObject.newValue);
        //---------- different conditions ----------------
        if (valueObject.designData.Conditions && valueObject.designData.Conditions[0] && valueObject.designData.Conditions[0].type === 'alternative') {
            setValue(viewData.Data, valueObject.designData.Conditions[0].value.split('-'), '');
        }
        if (valueObject.designData.Key === 'FinalDiscountAmount') {
            setValue(viewData.Data, ['FinalDiscountPercent'], ((valueObject.newValue / viewData?.Data?.TotalPriceBeforDiscount) * 100) || null );
        }
        if (valueObject.designData.Key === 'FinalDiscountPercent') {
            setValue(viewData.Data, ['FinalDiscountAmount'], (viewData?.Data?.TotalPriceBeforDiscount * (valueObject.newValue /100)) || null );
        }
        console.log(viewData);
        setViewData({ ...viewData });
    }

    function setValue(data, keys, value) {
        if (keys.length === 1) data[keys[0]] = value;
        else {
            setValue(data[keys[0]], keys.slice(1), value);
        }
    }

    function resetData() {
        loadData();
        setDisableMode(true);
    }

    function deleteViewData() {
        deleteData('/' + LocalStorage?.getConnectionKey() + properties?.deleteData + '?Index=' + viewData.Data.Index).then((data: any) => {
            navigate(-1);
        });
    }
    function handleSubmit(e: any) {
        setDataLoaded(false);
        let validate = 0;

        if (e) {
            e.preventDefault();
            let curFormData = e.target;

            /* ----------------- VALIDATION ------------------- */

            for (let i = 0; i < curFormData.length; i++) {
                //console.log(curFormData.elements[i]);
                if (curFormData.elements[i].required && !curFormData.elements[i].value) {
                    curFormData.elements[i].classList.add('required-error');
                    validate++;
                }
            }

            //clear errors
            document.querySelector('required-error')?.classList.remove('required-error');
            let requiredContainer = document.getElementsByClassName('required-true');
            for (let j = 0; j < requiredContainer.length; j++) {
                let input = (requiredContainer[j] as HTMLElement).querySelectorAll('input')[1];
                if (input && !input.value && viewData.DesignData.DesignElements[input.name]) {
                    let nameOfAlternative = viewData.DesignData.DesignElements[input.name].Conditions && viewData.DesignData.DesignElements[input.name].Conditions[0] ? viewData.DesignData.DesignElements[input.name].Conditions[0].value : null;
                    if (nameOfAlternative && (typeof viewData.Data[nameOfAlternative] === 'object' ? viewData.Data[nameOfAlternative]?.value : viewData.Data[nameOfAlternative])) {
                        //make a loop if more than 1 condition
                        console.log('alternative erfüllt');
                    } else {
                        (requiredContainer[j] as HTMLElement).querySelector('div[class*=-control]')?.classList.add('required-error');
                        validate++;
                    }
                }
            }
        }
        if (validate > 0) {
            setDataLoaded(true);
        } else {
            //--------------- CAUTION -----------
            //{...viewData.Data}
            let updateObj = {} as any;
            if (URLS.UpdateTask === properties?.updateData) {
                updateObj.Task = { ...viewData.Data };
                updateObj.Process = { ...viewData.Data.Process };
                if (viewData.Data.PersonInChargeID && typeof viewData.Data.PersonInChargeID === 'object') {
                    updateObj.Task.PersonInChargeName = viewData.Data.PersonInChargeID.label;
                    updateObj.Task.PersonInChargeID = viewData.Data.PersonInChargeID.value;
                }
                if (!viewData.Data.PersonInChargeID) {
                    updateObj.Task.PersonInChargeName = null;
                    updateObj.Task.PersonInChargeID = null;
                }
            } else if (URLS.UpdateOffer === properties?.updateData) {
                updateObj.OfferOrderData = { ...viewData.Data };
                updateObj.OfferOrderData.FinalDiscountPercent = updateObj.OfferOrderData.FinalDiscountPercent || 0;
                updateObj.OfferOrderData.FinalDiscountAmount = updateObj.OfferOrderData.FinalDiscountAmount || 0;

            } else updateObj = { ...viewData.Data };

            //Multiflexcenter saves mutliple Emails with ; - in HTML we need ,
            if (updateObj.EmailAddress) updateObj.EmailAddress = updateObj.EmailAddress.replace(',', ';');
            console.log(updateObj);
            updateData('/' + LocalStorage?.getConnectionKey() + properties?.updateData, { UpdateData: updateObj }).then((data: any) => {
                if (data.Meta.Success) {
                    setSaveMessage({ success: true, message: 14233 });
                    loadData();
                    setDataLoaded(true);
                } else {
                    let message = data.Meta.ErrorMessages[0]?.Message ? ': ' + data.Meta.ErrorMessages[0]?.Message : '';
                    setSaveMessage({ success: false, message: window.Dictionary.getTranslation(14234) + message });
                    setDataLoaded(true);
                }
                setDataLoaded(true);
                setDisableMode(true);
            });
        }
    }
    function getValue(data, keys) {
        if (keys[0] === 'EmptyElement') return null;
        if (keys.length === 1) {
            return data[keys[0]];
        } else {
            return getValue(data[keys[0]], keys.slice(1));
        }
    }

    function createNewProcessFromTask() {
        setShowProcessFromTask(true);
    }

    function takeOverTask() {
        var personInChargeDesigner = Object.entries(viewData.DesignData.DesignElements).find((el: any) => el[1].Key === 'PersonInChargeID');
        if (personInChargeDesigner) {
            changeData({ designData: personInChargeDesigner[1], newValue: LocalStorage.getData('UserData').Index });
        } else {
            setValue(viewData.Data, ['PersonInChargeID'], LocalStorage.getData('UserData').Index);
        }
        setValue(viewData.Data, ['PersonInChargeName'], LocalStorage.getData('UserData').CombinedName);
        handleSubmit(null);
    }

    var addTaskProperties = {
        actions: { add: true, tab: false, update: true, delete: true },
        addLabel: 14915,
        addView: 'TaskAddView',
        type: 'task',
        extraFilter: '&OnlyOwnTasks=true',
        createUrl: URLS.CreateTask,
        url: URLS.AllTasksData,
        customerFilter: 'Process.CustomerSupplierNumber',
        needCustomer: true,
        modalButtons: [{ label: window.Dictionary.getTranslation(14369), action: 'toOverview' }],
        detailView: 'TaskDetailView',
        detailLabel: window.Dictionary.getTranslation(35),
        getData: URLS.GetTask,
        updateData: URLS.UpdateTask,
        deleteData: URLS.DeleteTask,
    };

    function signOffer() {
        setDataLoaded(false);
        loadJsonPost('/' + LocalStorage?.getConnectionKey() + URLS.SignOfferOrder, { DocumentNumber: viewData.Data.OfferNumber })
            .then((data: any) => {
                console.log(data);
                if (data.Data) {
                    console.log(data.Data);
                    MFXLocalStorage.createSession('OfferSigningForward', window.location.href);
                    window.location.href = data.Data;
                    setDataLoaded(true);
                }
                else {
                    setSaveMessage({ success: false, message: 16379 });
                    setDataLoaded(true);
                }
                setDataLoaded(true);
            })
            .catch(e => {
                console.log(e);
                setDataLoaded(true);
            });
    }

    function sendEmail() {
        loadJsonData('/' + LocalStorage?.getConnectionKey() + URLS.PrepareEmail + '?Data.DocumentType=Offer&Data.DocumentNumber=' + viewData.Data.OfferNumber).then((data: any) => {
            if (data.Data && data.Data.Email) {
                window.location.href = 'mailto:'+data.Data.Email.Recipient + '?subject=' + (data.Data.Email.Subject || '') + '&body=' + (data.Data.Email.Body || '');
            }
        });
        
    }

    return (
        <>
            {dataLoaded && (
                <div className={'headline-signalcode headline-detail-view ' + type}>
                    <div>
                        {viewData.Data?.SignalCode && <span className={`signalcode_${viewData.Data.SignalCode}`}></span>}

                        {/* ----------------- SOME SPECIAL CONDITIONS ----------------- */}

                        {(type === 'task' || type === 'customervisit') && viewData.Data.Process && headline && (
                            <div className="headline-container">
                                <span className={`signalcode_${viewData.Data.Process.SignalCode ?? 'null'}`}></span>
                                <Headline designData={{ Headline: properties?.detailLabel + ': ' + headline.taskPosition }} />
                            </div>
                        )}
                        {type === 'process' && (
                            <div className="headline-container">
                                <Headline designData={{ Headline: window.Dictionary.getTranslation(1398) + ': ' + viewData.Data.Number }} />
                                {viewData.Data.Subject && <SmallHeadline designData={{ Headline: viewData.Data.Subject }} />}
                            </div>
                        )}
                        {(type === 'customer' || type === 'lead') && viewData.Data && <Headline designData={{ Headline: properties?.detailLabel + ': ' + viewData.Data.Number, icon: props.containerData?.Icon, iconColor: props.containerData?.iconColor }} />}
                        {type === 'offer' && viewData.Data && <Headline designData={{ Headline: properties?.detailLabel + ': ' + (viewData.Data.Keyword ?? viewData.Data.Number), icon: props.containerData?.icon, iconColor: props.containerData?.iconColor }} />}
                        {type === 'contactperson' && viewData.Data && (
                            <MainHeadline
                                designData={{
                                    Headline: (viewData.Data.Firstname ?? '') + ' ' + (viewData.Data.Lastname ?? ''),
                                    Subheadline: properties?.detailLabel + ': ' + index,
                                    icon: props.containerData.icon,
                                    iconColor: props.containerData.iconColor,
                                }}
                            />
                        )}
                    </div>
                    {viewData.Data.Finished && (
                        <div className={`success-${viewData.Data.Finished} small`}>
                            <div className="icon-style-arrow">
                                {viewData.Data.Finished && <i className="icon-check"></i>}
                                {!viewData.Data.Finished && <i className="icon-close"></i>}
                            </div>
                        </div>
                    )}
                </div>
            )}
            {type === searchParams.get('type') && (
                <div className="mfx-back-button-container">
                    {!search && (
                        <button
                            className="mfx-back-button"
                            onClick={() => {
                                window.history.back();
                                //navigate(-1);
                            }}>
                            <i className="icon-arrow-left"></i>
                            {window.Dictionary.getTranslation(774)}
                        </button>
                    )}
                </div>
            )}

            {dataLoaded && type === 'contactperson' && (
                <div className="mfx-icon-links">
                    <div className="mfx-links">
                        {viewData.Data.EmailAddress && (
                            <a className="mfx-button" href={`mailto:${viewData.Data.EmailAddress}`}>
                                <i className="icon-mail"></i>
                            </a>
                        )}
                        {viewData.Data.PhoneNumber && (
                            <a className="mfx-button" href={`tel:${viewData.Data.PhoneNumber}`}>
                                <i className="icon-phone"></i>
                            </a>
                        )}
                        {viewData.Data.MobilePhoneNumber && (
                            <a className="mfx-button" href={`tel:${viewData.Data.MobilePhoneNumber}`}>
                                <i className="icon-mobile"></i>
                            </a>
                        )}
                        {viewData.Data.City && (
                            <a className="mfx-button" target="_blank" href={`https://google.com/maps/place/${viewData.Data.Street}+${viewData.Data.City}`} rel="noreferrer">
                                <i className="icon-marker"></i>
                            </a>
                        )}
                    </div>
                </div>
            )}
            <form className={`mfx-view container-size mfx-detailview disable-${disableMode} ${type}`} onSubmit={handleSubmit} id="EditForm">
                {properties?.actions && dataLoaded && (
                    <div className="mfx-action-buttons">
                        <Editbutton disableChangeHandler={() => setDisableMode(false)} disableMode={disableMode} deleteChangeHandler={() => deleteViewData()} resetChangeHandler={() => resetData()} confirmNotice={14196} submit={true} actions={properties.actions} />
                        {type === 'task' && disableMode && (
                            <>
                                <button type="button" id="createNewProcessFromTaskButton" name="createNewProcessFromTask" className="mfx-button mfx-button-type-icon" onClick={() => createNewProcessFromTask()}>
                                    <i className="icon-add"></i>
                                </button>
                                <button
                                    type="button"
                                    id="calendarButton"
                                    name="calendar"
                                    className="mfx-button mfx-button-type-icon"
                                    title={window.Dictionary.getTranslation(12820)}
                                    onClick={() => {
                                        setShowNewProcessFromTask(true);
                                    }}>
                                    <i className="icon-calendar"></i>
                                </button>
                                <button
                                    type="button"
                                    id="adoptTaskButton"
                                    name="adoptTask"
                                    className="mfx-button mfx-button-type-icon"
                                    title={window.Dictionary.getTranslation(1459)}
                                    onClick={() => {
                                        setShowQuestion(true);
                                    }}>
                                    <i className="icon-marker"></i>
                                </button>
                            </>
                        )}
                        {type === 'offer' && disableMode && (
                            <>
                                {<button type="button" className="mfx-button mfx-button-type-icon" onClick={() => signOffer()}>
                                    <i className="icon-offer"></i>
                                </button>}
                                <button type="button" className="mfx-button mfx-button-type-icon" onClick={() => sendEmail()}>
                                    <i className="icon-mail"></i>
                                </button>
                            </>
                        )}
                    </div>
                )}
                {!dataLoaded && <Loading />}
                <div className="mfx-detailview-formelements">
                    {dataLoaded && viewData.DesignData && (
                        <>
                            {viewData.DesignData.ElementsOrder &&
                                viewData.DesignData.ElementsOrder[0].map((key: string, idx) => {
                                    //console.log(curDesignData.DesignElements[key], vData.Data[key]);
                                    if (viewData.DesignData.DesignElements[key]) {
                                        const designD = viewData.DesignData.DesignElements[key];
                                        const keys = (key as string).split('-');
                                        if (keys[0] === 'Data') keys.splice(0, 1);

                                        //if (designD.CheckedInDesigner) {
                                        return (
                                            <GenericElement
                                                customData={getValue(viewData.Data, keys)}
                                                designData={{ ...designD, viewtype: type }}
                                                key={idx + idx}
                                                index={idx}
                                                isRecording={recording}
                                                setRecording={val => setRecording(val)}
                                                disableMode={disableMode}
                                                noLabel={false}
                                                onChangeGenericElement={(valueObject: any) => changeData({ ...valueObject, idx: idx })}
                                            />
                                        );
                                        //}
                                        //return getGenericElement(designD, idx, idx, key);
                                    }
                                    return <></>;
                                })}
                            {!viewData.DesignData.ElementsOrder &&
                                viewData.DesignData.DesignElements &&
                                Object.entries(viewData.DesignData.DesignElements)
                                    .sort((a: any, b: any) => {
                                        return a[1].Order > b[1].Order ? 1 : -1;
                                    })
                                    .map((key: any, idx) => {
                                        const designD = key[1];
                                        const keys = key[1].Key?.split('-');
                                        var customData = getValue(viewData.Data, keys);
                                        if (keys.includes('ProfilingValues')) {
                                            customData = { Profiling: customData, Assignment: viewData.Data?.Assignment ?? viewData.Data?.Process?.Assignment,  Category : viewData.Data?.Categorie };
                                        }
                                        if (designD.Elementtype === 'download') {
                                            customData = { ...viewData.Data };
                                        }
                                        return (
                                            <GenericElement
                                                customData={customData}
                                                designData={{ ...designD, viewtype: type }}
                                                key={idx + idx}
                                                index={idx}
                                                isRecording={recording}
                                                setRecording={val => setRecording(val)}
                                                disableMode={disableMode}
                                                noLabel={false}
                                                onChangeGenericElement={(valueObject: any) => changeData({ ...valueObject, idx: idx })}
                                            />
                                        );
                                    })}
                        </>
                    )}
                </div>
            </form>
            {showProcessFromTask && (
                <ModalPopup modalClass="mfx-add-modal">
                    <AddViewProvider
                        changeView={() => setShowProcessFromTask(false)}
                        viewData={viewData}
                        withFilledData={true}
                        layoutName={'AddProcessFromTask'}
                        viewName={addTaskProperties?.addView}
                        fixCustomer={LocalStorage.getCurrentPath().indexOf('CUSTOMER') > 0 ? true : false}
                        viewSettings={addTaskProperties}>
                        <div className="mfx-action-buttons">
                            <Addbutton
                                disableChangeHandler={() => {
                                    setShowProcessFromTask(false);
                                }}
                                disableMode={showProcessFromTask}
                                save={false}
                            />
                        </div>
                    </AddViewProvider>
                </ModalPopup>
            )}
            {showNewProcessFromTask && (
                <ModalPopup modalClass="mfx-add-modal">
                    <AddViewProvider
                        changeView={() => setShowNewProcessFromTask(false)}
                        viewData={viewData}
                        withFilledData={true}
                        withoutProcess={true}
                        layoutName={'AddProcessFromTask'}
                        viewName={addTaskProperties?.addView}
                        fixCustomer={LocalStorage.getCurrentPath().indexOf('CUSTOMER') > 0 ? true : false}
                        viewSettings={addTaskProperties}>
                        <div className="mfx-action-buttons">
                            <Addbutton
                                disableChangeHandler={() => {
                                    setShowNewProcessFromTask(false);
                                }}
                                disableMode={showNewProcessFromTask}
                                save={false}
                            />
                        </div>
                    </AddViewProvider>
                </ModalPopup>
            )}
            {saveMessage && <SavedModal saveMessage={saveMessage} closeIcon={true} handleClose={() => setSaveMessage(null)} />}
            {showQuestion && <QuestionModal question={1460} handleYes={() => setShowQuestion(takeOverTask)} handleNo={() => setShowQuestion(false)} handleClose={() => setShowQuestion(false)} />}
        </>
    );
}
