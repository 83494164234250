/**
 * Renders a Info Button and Text
 * Disappears after 5 Seconds
 * @returns {JSX.Element} Info.
 */

const WikiInfo = props => {
    function goToUrl(e) {
        var autoUrl = '';
        if (!props.url) {
            if (window.location.href?.includes('/designer/')) {
                var url = window.location.href?.split('?')[0]?.split('/')?.pop();
                var segm = window.location.href?.split('?')[0].split('/');
                var url2 = segm[segm.length - 2];
                console.log(url2);
                switch (url2) {
                    case 'pages':
                        autoUrl = 'seiten-pages';
                        break;
                    case 'stylings':
                        autoUrl = 'stylings-farben-logo';
                        break;
                    case 'menu':
                        autoUrl = 'client-menu';
                        break;
                    default:
                        autoUrl = '';
                        break;
                }
                if (url.includes('DetailView') || url.includes('AddView')) {
                    autoUrl = 'detail-addviews';
                }
                if (!autoUrl) {
                    console.log(url);

                    switch (url) {
                        case 'DashboardView':
                            autoUrl = 'dashboard-views';
                            break;
                        case 'FreeView':
                            autoUrl = 'custom-views';
                            break;
                        case 'usersettings':
                            autoUrl = 'benutzereinstellungen';
                            break;
                        case 'usergroupsettings':
                            autoUrl = 'benutzergruppeneinstellungen';
                            break;
                        case 'importexport':
                            autoUrl = 'json-import-export';
                            break;
                        default:
                            autoUrl = 'table-views';
                            break;
                    }
                }
            } else {
                var url = window.location.href?.split('?')[0]?.split('/')?.pop();
                console.log(url);
                switch (url) {
                    case 'customers':
                    case 'offers':
                    case 'leads':
                    case 'contacts':
                    case 'tasks':
                    case 'systeminfo':
                    case 'deligatedtasks':
                        autoUrl = 'tabellen-ansichten';
                        break;
                    case 'detailview':
                    case 'customer':
                        autoUrl = 'detail-ansicht';
                        break;
                    default:
                        autoUrl = 'header';
                        break;
                }
            }
        }
        window.open('https://help.pollex-lc.com/mxcrm/' + autoUrl ?? props.url ?? '', '_blank').focus();
    }

    return (
        <>
            <i title={props.text} className={'icon-info wiki-info-icon'} onClick={goToUrl}></i>
        </>
    );
};

export default WikiInfo;
