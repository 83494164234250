import { useEffect, useState } from 'react';
import GenericElement from '../Utils/GenericElement';
import loadJsonData from '../Utils/loadJsonData';
import LocalStorage from 'Helpers/MFXLocalStorage';
import URLS from '../Utils/URLs.API';
import Loading from 'Components/Lib/Elements/Loading';
import { getDayShort, getDurationInMinutesABS, minuteToHHMM, toDateFormat, toISOOffset } from '../../../Helpers/dateHelper';
import InputElement from '../Elements/InputElement';
import loadJsonPut from '../Utils/jsonPut';
import Editbutton from '../Elements/Editbutton';

export function TimeRecordingPositionsView(props: any) {
    const [data, setData] = useState(null as any);
    const [loading, setLoading] = useState(true);
    const [fromDate, setFromDate] = useState(startOfMonth(new Date()));
    const [toDate, setToDate] = useState(endOfMonth(new Date()));
    const [workingTypes, setWorkingTypes]: any = useState(null);

    useEffect(() => {
        loadData();
    }, [fromDate, toDate]);

    useEffect(() => {
        loadJsonData('/' + LocalStorage.getConnectionKey() + URLS.GetDropdownValues + 'WorkingTimeType').then((data: any) => {
            if (data.Meta.Success && data.Data) {
                setWorkingTypes(data.Data);
            }
        });
    }, []);

    function loadData() {
        setLoading(true);
        let start = toDateFormat(fromDate);
        let end = toDateFormat(toDate);
        loadJsonData('/' + LocalStorage.getConnectionKey() + URLS.GetTimeRecordingEntries + '?Data.Filter.BeginDate=' + start + '&Data.Filter.EndDate=' + end).then((data: any) => {
            if (data.Meta.Success && data.Data && data.Data.TimeRecordingEntriesData) {
                setData(data.Data.TimeRecordingEntriesData);
            }
            setLoading(false);
        });
    }

    function endOfMonth(date) {
        return new Date(date.getFullYear(), date.getMonth() + 1, 0, 12, 0, 0);
    }

    function startOfMonth(date) {
        return new Date(date.getFullYear(), date.getMonth(), 1, 12, 0, 0);
    }

    function onChange(id, key, newValue) {
        if ((key === 'WorkingTimeFrom' && data[id].WorkingTimeUntil && new Date(newValue) > new Date(data[id].WorkingTimeUntil)) || (key === 'WorkingTimeUntil' && data[id].WorkingTimeFrom && new Date(data[id].WorkingTimeFrom) > new Date(newValue))) {
            console.log('WorkingTimeFrom > WorkingTimeUntil');
            return;
        }
        data[id][key] = newValue;
        if (key === 'Date') {
            if (data[id].WorkingTimeFrom) {
                var newWorkingTimeFrom = new Date(newValue);
                data[id].WorkingTimeFrom = new Date(data[id].WorkingTimeFrom);
                newWorkingTimeFrom.setHours(data[id].WorkingTimeFrom.getHours(), data[id].WorkingTimeFrom.getMinutes(), data[id].WorkingTimeFrom.getSeconds());
            }
            if (data[id].WorkingTimeUntil) {
                var newWorkingTimeUntil = new Date(newValue);
                data[id].WorkingTimeUntil = new Date(data[id].WorkingTimeUntil);
                newWorkingTimeUntil.setHours(data[id].WorkingTimeUntil.getHours(), data[id].WorkingTimeUntil.getMinutes(), data[id].WorkingTimeUntil.getSeconds());
            }
        }
        setData(data.map(o => ({ ...o })));
    }

    function save(e) {
        setLoading(true);
        e.preventDefault();
        var body = {
            Data: {
                TimeRecordingEntriesData: [
                    ...data.map(d => {
                        return { ...d, Text: d.Text ?? '', Duration: d.Duration > 0 && d.Duration < 15 ? d.Duration : getDurationInMinutesABS(d.WorkingTimeFrom, d.WorkingTimeUntil), WorkingTimeFrom: toISOOffset(d.WorkingTimeFrom), WorkingTimeUntil: toISOOffset(d.WorkingTimeUntil) };
                    }),
                ],
            },
        };
        loadJsonPut('/' + LocalStorage.getConnectionKey() + URLS.UpdateTimeRecordingEntries, body).then((data: any) => {
            loadData();
        });
    }

    return (
        <>
            {data && !loading && (
                <>
                    <div className="mfx-action-buttons">
                        <Editbutton saveChangeHandler={e => save(e)} />
                    </div>
                    <div className="mfx-table-container">
                        <div className="mfx-from-to-date-wrapper">
                            <InputElement id="date-for" customerData={fromDate} designData={{ Type: 'date', Defaultvalue: fromDate, Key: 'ChangeDate', Label: 1586 }} disableMode={false} onChangeHandler={e => setFromDate(new Date(e.newValue))}></InputElement>
                            <InputElement customerData={toDate} designData={{ Type: 'date', Defaultvalue: toDate, Key: 'ChangeDate', Label: 1587 }} disableMode={false} onChangeHandler={e => setToDate(new Date(e.newValue))}></InputElement>
                        </div>
                        <table className="mfx-table">
                            {loading && <Loading />}

                            <>
                                <tr>
                                    {props.designer?.TableElements &&
                                        props.designer?.TableElements[0] &&
                                        Object.entries(props.designer.TableElements[0]).map((designer: any, idx) => {
                                            return <th>{window.Dictionary.getTranslation(designer[1].Label)}</th>;
                                        })}
                                </tr>
                                {data.map((d, id) => {
                                    return (
                                        <tr>
                                            {props.designer?.TableElements &&
                                                props.designer?.TableElements[0] &&
                                                Object.entries(props.designer.TableElements[0]).map((designer: any, idx) => {
                                                    var value = d[designer[1].Key];
                                                    switch (designer[1].Key) {
                                                        case 'Day':
                                                            value = getDayShort(d.Date) + '.';
                                                            break;
                                                        case 'Duration':
                                                            if (d.Duration > 0 && d.Duration < 15) {
                                                                break;
                                                            }
                                                            value = getDurationInMinutesABS(d.WorkingTimeFrom, d.WorkingTimeUntil);
                                                            break;
                                                        case 'DurationTime':
                                                            value = minuteToHHMM(getDurationInMinutesABS(d.WorkingTimeFrom, d.WorkingTimeUntil));
                                                            break;
                                                        case 'WorkingType':
                                                            return (
                                                                <td>
                                                                    <div className="mfx-input-element">
                                                                        <select
                                                                            value={d.CustomWorkingType + '-' + d.WorkingType}
                                                                            onChange={e => {
                                                                                onChange(id, designer[1].Key2, e.target.value.split('-')[0]);
                                                                                onChange(id, designer[1].Key, e.target.value.split('-')[1]);
                                                                            }}>
                                                                            {workingTypes?.map(t => {
                                                                                return (
                                                                                    <option value={t.Value}>
                                                                                        {' '}
                                                                                        {t.Value.split('-')[0]} - {t.Name}
                                                                                    </option>
                                                                                );
                                                                            })}
                                                                        </select>
                                                                    </div>
                                                                </td>
                                                            );
                                                    }
                                                    return (
                                                        <td>
                                                            <GenericElement customData={value} designData={{ ...designer[1] }} key={id + idx} index={idx} onChangeGenericElement={e => onChange(id, e.designData.Key, e.newValue)} />
                                                        </td>
                                                    );
                                                })}
                                        </tr>
                                    );
                                })}
                            </>
                        </table>
                    </div>
                </>
            )}
        </>
    );
}
