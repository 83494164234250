import Loading from 'Components/Lib/Elements/Loading';
import GenericElement from 'Components/Lib/Utils/GenericElement';
import { useEffect, useState } from 'react';
import ModalPopup from '../Common/ModalPopup';
import { AddViewProvider } from './AddView';
import Addbutton from '../Elements/Addbutton';
import { getPropertiesPerType } from 'Helpers/getPropertiesPerType';
import MFXLocalStorage from '../../../Helpers/MFXLocalStorage';
import loadJsonData from '../Utils/loadJsonData';
import URLS from '../Utils/URLs.API';
import Quicklinks from '../../../Data/Designer/Defaultviews/quicklinks.json';

/**
 * Renders Quicklinks.
 * @param {any} props given by caller
 * @returns {JSX.Element} Rendered component
 */
function FreeView(props: any): JSX.Element {
    let elements = props.designer.CustomElements[0].Data[0];

    //const elements = useApi<ViewElement[]>(URLS.Quicklinks);
    const [addMode, setAddMode] = useState({
        on: false,
        type: '',
        curObj: {} as any,
    });
    function onClickHandler(type) {
        setAddMode({ ...addMode, on: true, type: type, curObj: getPropertiesPerType(type) });
    }

    return (
        <>
            {!elements && <Loading title="Quicklinks" />}

            {elements && (
                <div className="mfx-view">
                    <div className="mfx-view-quicklinks">
                        {!props.mytask &&
                            elements.map((e, idx) => {
                                if (e.Elementtype === 'quicklink') {
                                    return <GenericElement key={idx} designData={Quicklinks[e.Link] as any} onChangeGenericElement={() => onClickHandler(e.Link)} />;
                                }
                                else{
                                  return <GenericElement key={idx} designData={e}/>;
                                }
                            })}
                    </div>
                </div>
            )}
            {addMode.on && (
                <ModalPopup modalClass="mfx-add-modal">
                    <AddViewProvider changeView={() => setAddMode({ ...addMode, on: false, type: '' })} viewName={addMode.curObj.addView} fixCustomer={MFXLocalStorage.getCurrentPath().indexOf('CUSTOMER') > 0 ? true : false} viewSettings={addMode.curObj}>
                        <div className="mfx-action-buttons">
                            <Addbutton
                                disableChangeHandler={() => {
                                    setAddMode({ ...addMode, on: !addMode.on, type: '', curObj: {} });
                                }}
                                disableMode={addMode.on}
                                save={false}
                            />
                        </div>
                    </AddViewProvider>
                </ModalPopup>
            )}
        </>
    );
}

export default FreeView;
