import URLS from '../Components/Lib/Utils/URLs.API';

export function getPropertiesPerType(viewName) {
    switch (viewName) {
        case 'TasksView':
        case 'task':
        case 'customervisit':
            return {
                actions: { add: true, tab: false, update: true, delete: true },
                addLabel: 15582,
                addView: 'TaskAddView',
                type: 'task',
                extraFilter: 'OnlyOwnTasks=true',
                createUrl: URLS.CreateTask,
                url: URLS.AllTasksData,
                customerFilter: 'Process.CustomerSupplierNumber',
                needCustomer: true,
                modalButtons: [/*{label: window.Dictionary.getTranslation(14378), url:'processes/detailview?type=task&', action: 'toCreatedData'},*/ { label: window.Dictionary.getTranslation(14369), action: 'toOverview' }],
                detailView: 'TaskDetailView',
                detailLabel: window.Dictionary.getTranslation(35),
                getData: URLS.GetTask,
                updateData: URLS.UpdateTask,
                deleteData: URLS.DeleteTask,
            };
        case 'LeadTasksView':
        case 'lead':
            return {
                actions: { add: true, tab: false, update: true, delete: true },
                addLabel: 14177,
                addView: 'TaskAddView',
                type: 'task',
                extraFilter: 'OnlyOwnTasks=true',
                createUrl: URLS.CreateTask,
                url: URLS.AllTasksData,
                customerFilter: 'Process.CustomerSupplierNumber',
                needCustomer: false,
                modalButtons: [/*{label: window.Dictionary.getTranslation(14378), url:'processes/detailview?type=task&', action: 'toCreatedData'},*/ { label: window.Dictionary.getTranslation(14369), action: 'toOverview' }],
                detailView: 'LeadDetailView',
                detailLabel: 'Lead',
                getData: URLS.GetLead,
                updateData: URLS.UpdateLead,
                deleteData: URLS.DeleteLead,
            };
        case 'CustomerVisitsView':
            return {
                actions: { add: false, tab: false },
                addLabel: 14177,
                addView: 'CustomerVisitsView',
                type: 'customervisit',
                extraFilter: 'TaskType=Visit',
                createUrl: URLS.CreateTask,
                url: URLS.AllTasksData,
                customerFilter: 'Process.CustomerSupplierNumber',
                needCustomer: true,
            };
        case 'CustomerView':
        case 'customer':
            return {
                actions: { add: false, tab: true, update: true },
                addLabel: '',
                addView: '',
                type: 'customer',
                extraFilter: '',
                createUrl: 'TODO CREATECUSTOMER',
                detailView: 'CustomerDetailView',
                url: URLS.AllCustomersData,
                getData: URLS.GetCustomer,
                customerFilter: '',
                needCustomer: true,
                updateData: URLS.UpdateCustomer,
                detailLabel: window.Dictionary.getTranslation(1270),
            };
        case 'LeadsView':
            return {
                actions: { add: true, tab: false },
                addLabel: 14223,
                addView: 'LeadAddView',
                detailView: 'LeadDetailView',
                enableqrcode: true,
                qrPrefix: 'ContactPersonData-',
                type: 'lead',
                extraFilter: '',
                createUrl: URLS.CreateLead,
                url: URLS.AllLeadsData,
                customerFilter: '',
                needCustomer: false,
            };
        case 'OfferView':
        case 'offer':
            return {
                actions: { add: false, tab: false, update: true, delete: false },
                addLabel: '',
                addView: '',
                type: 'offer',
                extraFilter: '',
                createUrl: 'TODO CREATEOFFER',
                url: URLS.AllOffersData,
                customerFilter: 'CustomerNumber',
                needCustomer: true,
                detailView: 'OfferDetailView',
                detailLabel: window.Dictionary.getTranslation(648),
                getData: URLS.GetOffer,
                updateData: URLS.UpdateOffer,
            };
        case 'OfferPositionsView':
            return {
                actions: { add: false, tab: false, update: false, delete: false },
                addLabel: '',
                addView: '',
                type: 'offer',
                extraFilter: '',
                createUrl: 'TODO CREATEOFFER',
                url: URLS.GetOfferOrderPositions,
                customerFilter: 'CustomerNumber',
                needCustomer: true,
                detailView: 'OfferDetailView',
                detailLabel: window.Dictionary.getTranslation(648),
                getData: URLS.GetOfferOrderPositions,
            };
        case 'ContactView':
        case 'contactperson':
            return {
                actions: { add: true, tab: false, update: true, delete: true },
                addLabel: 14303,
                addView: 'ContactPersonAddView',
                enableqrcode: true,
                qrPrefix: '',
                type: 'contactperson',
                extraFilter: '',
                createUrl: URLS.CreateContactPerson,
                url: URLS.AllContactPersonsData,
                customerFilter: 'CustomerNumber',
                needCustomer: true,
                detailView: 'ContactPersonDetailView',
                detailLabel: 'Ansprechpartner',
                getData: URLS.GetContactPerson,
                updateData: URLS.UpdateContactPerson,
                deleteData: URLS.DeleteContactPerson,
            };
        case 'NoteView':
        case 'note':
            return {
                actions: { add: true, tab: false },
                addLabel: 14355,
                addView: 'NoteAddView',
                type: 'note',
                extraFilter: '',
                createUrl: URLS.CreateNote,
                customerFilter: '',
                needCustomer: false,
                modalButtons: [{ label: window.Dictionary.getTranslation(14369), url: '/processes/user' }],
            };
        case 'process':
            return {
                actions: { update: true, add: false, tab: false },
                addLabel: 1398,
                addView: 'ProcessAddView',
                type: 'process',
                extraFilter: '',
                getData: URLS.GetProcess,
                createUrl: URLS.CreateProcess,
                updateData: URLS.UpdateProcess,
                detailView: 'ProcessDetailView',
                customerFilter: '',
                needCustomer: true,
            };
        case 'timerecording':
            return {
                actions: { update: false, add: false, tab: false },
                type: 'timerecording',
                addView: 'TimeRecordingAddView',
                addLabel: 16286,
                extraFilter: '',
                getData: URLS.GetTimeRecordingEntries,
                createUrl: URLS.CreateTimeRecordingEntries,
                updateData: URLS.UpdateTimeRecordingEntries,
                detailView: 'TimeRecordingDetailView',
                customerFilter: '',
                needCustomer: false,
            };
        case 'expensereport':
            return {
                actions: { update: false, add: false, tab: false },
                type: 'expensereport',
                addView: 'ExpenseReportAddView',
                addLabel: 16287,
                extraFilter: '',
                getData: URLS.GetTravelExpenses,
                createUrl: URLS.UpdateTravelExpenses,
                updateData: URLS.UpdateTravelExpenses,
                detailView: 'ExpenseReportDetailView',
                customerFilter: '',
                needCustomer: false,
            };
        case 'SystemInfoView':
            return {
                actions: { update: false, add: false, tab: false },
                type: 'systeminfo',
                extraFilter: '',
                url: URLS.GetAllSystemInfo,
                getData: URLS.GetProcess,
                createUrl: URLS.CreateProcess,
                updateData: URLS.UpdateProcess,
                detailView: '',
                customerFilter: '',
                specialCustomerFilter: 'Data.CustomerNumber',
                needCustomer: false,
            };
        default:
            return;
    }
}

export function getContext(data, viewName) {
    switch (viewName) {
        case 'TasksView':
            return {};
        case 'CustomerView':
            return {
                customerName: data.RawAddress.Name1,
            };
        case 'LeadsView':
            return {};
        case 'OfferView':
            return {};
        case 'ContactView':
            return {};
        default:
            return {};
    }
}
//#endregion View Differentiator
