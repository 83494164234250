/* eslint-disable jsx-a11y/label-has-associated-control */
import { useEffect, useState } from 'react';
import SessionStorage from 'Helpers/MFXSessionStorage';
import GenericElement from '../Utils/GenericElement';
import loadJsonData from '../Utils/loadJsonData';
import LocalStorage from 'Helpers/MFXLocalStorage';
import URLS from '../Utils/URLs.API';
import { getDurationInMinutes, minuteToHHMM, toDateFormat, toISOOffset, workingTimeRounding } from '../../../Helpers/dateHelper';
import loadJsonPost from '../Utils/loadJsonPost';
import loadJsonPut from '../Utils/jsonPut';

export function TimeRecordingView(props: any) {
    const [data, setData] = useState(null as any);
    const [timeRecording, setTimeRecording] = useState(0);
    const [running, setRunning] = useState(false);
    const [year, setYear] = useState(new Date().getFullYear());
    const [month, setMonth] = useState(new Date().getMonth() + 1);

    useEffect(() => {
        var date = new Date();
        var username = LocalStorage.getData('UserName');
        loadJsonData('/' + LocalStorage.getConnectionKey() + URLS.GetTimeRecordingSummary + '?Data.TargetUser=' + username + '&Data.WorkingUser=' + username + '&Data.Year=' + year + '&Data.KwMonth=' + month).then((data: any) => {
            if (data?.Data?.TimeRecordingSummaryData) {
                setData({
                    SumWorkingHours: minuteToHHMM(data.Data.TimeRecordingSummaryData.SummeGeleisteteArbeitszeit),
                    SumWorkingHoursPlanned: minuteToHHMM(data.Data.TimeRecordingSummaryData.SummeSollArbeitszeit),
                    SumWorkingHoursPlannedToday: minuteToHHMM(data.Data.TimeRecordingSummaryData.SummeSollArbeitszeitHeute),
                    ConsumedHoliday: data.Data.TimeRecordingSummaryData.SummeUrlaubZeitraum,
                    SumCarryOver: minuteToHHMM(data.Data.TimeRecordingSummaryData.SummeUebertragZeitraum),
                    SumCarryOverToday: minuteToHHMM(data.Data.TimeRecordingSummaryData.SummeUebertragZeitraumHeute),
                    OldHoliday: data.Data.TimeRecordingSummaryData.UrlaubVorKwMonat,
                    CarryOverPrevious: minuteToHHMM(data.Data.TimeRecordingSummaryData.UebertragVorKwMonat),
                    NewHoliday: data.Data.TimeRecordingSummaryData.NeuerUrlaub,
                    RemainingCarryOver: minuteToHHMM(data.Data.TimeRecordingSummaryData.VerbleibenderUebertrag),
                    RemainingCarryOverToday: minuteToHHMM(data.Data.TimeRecordingSummaryData.VerbleibenderUebertragHeute),
                    RemainingHoliday: data.Data.TimeRecordingSummaryData.VerbleibenderUrlaub,
                    Years: data.Data.TimeRecordingSummaryData.Jahre,
                });
            }
        });
        loadTimeRecording();
    }, [year, month]);

    function loadTimeRecording() {
        let today = new Date();
        loadJsonData('/' + LocalStorage.getConnectionKey() + URLS.GetTimeRecordingEntries + '?Data.Filter.BeginDate=' + toDateFormat(today) + '&Data.Filter.EndDate=' + toDateFormat(new Date(today.getFullYear(), today.getMonth(), today.getDate() + 1))).then((data: any) => {
            if (data.Meta.Success && data.Data && data.Data.TimeRecordingEntriesData) {
                var time = 0;
                var runningVal = false;
                data.Data.TimeRecordingEntriesData.forEach(r => {
                    if (!r.WorkingTimeUntil) {
                        setRunning(true);
                        runningVal = true;
                    }
                    time += getDurationInMinutes(r.WorkingTimeFrom, r.WorkingTimeUntil ?? new Date());
                });
                setTimeRecording(Math.trunc(time));
                if (runningVal) {
                    timeTicker();
                }
            }
        });
    }

    function timeTicker() {
        setTimeout(function () {
            setTimeRecording(prev => {
                return Math.trunc(prev + 1);
            });
            timeTicker();
        }, 60000);
    }

    function getValue(keys) {
        if (keys.length === 1) return data[keys[0]];
        else if (keys.length === 2) return [data[keys[0]], data[keys[1]]];
        return '';
    }

    function onStartStop(running) {
        let today = new Date();
        loadJsonData('/' + LocalStorage.getConnectionKey() + URLS.GetTimeRecordingEntries + '?Data.Filter.BeginDate=' + toDateFormat(today) + '&Data.Filter.EndDate=' + toDateFormat(new Date(today.getFullYear(), today.getMonth(), today.getDate() + 1))).then((data: any) => {
            if (data.Meta.Success && data.Data && data.Data.TimeRecordingEntriesData) {
                var unfinished = data.Data.TimeRecordingEntriesData.find(r => !r.WorkingTimeUntil);
                var body;
                if (unfinished) {
                    var timeUntil = toISOOffset(workingTimeRounding(new Date()));
                    body = {
                        Data: {
                            TimeRecordingEntriesData: [
                                {
                                    Date: unfinished.Date,
                                    WorkingTimeFrom: unfinished.WorkingTimeFrom,
                                    WorkingTimeUntil: timeUntil,
                                    WorkingType: unfinished.WorkingType,
                                    CustomWorkingType: unfinished.CustomWorkingType,
                                    Duration: getDurationInMinutes(unfinished.WorkingTimeFrom, timeUntil),
                                    Index: unfinished.Index,
                                    EmployeeIndex: unfinished.EmployeeIndex,
                                    Text: unfinished.Text ?? '',
                                    CanDelete: false,
                                },
                            ],
                        },
                    };
                    loadJsonPut('/' + LocalStorage.getConnectionKey() + URLS.UpdateTimeRecordingEntries, body).then((data: any) => {
                        setRunning(false);
                        loadTimeRecording();
                    });
                } else {
                    var timeFrom = toISOOffset(workingTimeRounding(new Date()));
                    var date = new Date();
                    date.setHours(0, 0, 0);
                    body = {
                        Data: {
                            TimeRecordingEntriesData: [
                                {
                                    Date: toISOOffset(date),
                                    WorkingTimeFrom: timeFrom,
                                    WorkingTimeUntil: null,
                                    WorkingType: 1,
                                    CustomWorkingType: 'N',
                                    Duration: 0,
                                    Index: 0,
                                    EmployeeIndex: 0,
                                    Text: '',
                                    CanDelete: false,
                                },
                            ],
                        },
                    };
                    loadJsonPost('/' + LocalStorage.getConnectionKey() + URLS.CreateTimeRecordingEntries, body).then((data: any) => {
                        setRunning(true);
                        loadTimeRecording();
                    });
                }
            }
        });
    }

    return (
        <>
            {data && (
                <div className="time-recording">
                    <h3>{LocalStorage.getData('UserData').CombinedName}</h3>
                    <div className="time-recording-row">
                        <span className="time-recording-text">{window.Dictionary.getTranslation(6022)}:</span>
                        <button className="time-recording-button" style={{ backgroundColor: running ? 'var(--errorColor)' : 'var(--successColor)' }} onClick={e => onStartStop(running)}>
                            {!running && <i className="icon-start" style={{fontSize: '30px'}} />}
                            {running && <i className="icon-stop" style={{fontSize: '30px'}}/>}
                        </button>
                        <h1>{minuteToHHMM(timeRecording)}</h1>
                        {window.innerWidth > window.mobileBreakPoint && (
                            <div>
                                <div>
                                    <label>{window.Dictionary.getTranslation(3698)}</label>
                                    <select value={year} onChange={(e: any) => setYear(e.target.value)}>
                                        {data.Years?.map((y: any) => (
                                            <option value={y}>{y}</option>
                                        ))}
                                        <option value={Math.max(...data.Years) + 1}>{Math.max(...data.Years) + 1}</option>
                                    </select>
                                </div>
                                <div>
                                    <label>{window.Dictionary.getTranslation(3699)}</label>
                                    <select value={month} onChange={(e: any) => setMonth(e.target.value)}>
                                        <option value="1">1</option>
                                        <option value="2">2</option>
                                        <option value="3">3</option>
                                        <option value="4">4</option>
                                        <option value="5">5</option>
                                        <option value="6">6</option>
                                        <option value="7">7</option>
                                        <option value="8">8</option>
                                        <option value="9">9</option>
                                        <option value="10">10</option>
                                        <option value="11">11</option>
                                        <option value="12">12</option>
                                    </select>
                                </div>
                            </div>
                        )}
                    </div>
                    {window.innerWidth <= window.mobileBreakPoint && (
                        <div className='mobile-year-month'>
                            <div>
                                <label>{window.Dictionary.getTranslation(3698)}</label>
                                <select value={year} onChange={(e: any) => setYear(e.target.value)}>
                                    {data.Years?.map((y: any) => (
                                        <option value={y}>{y}</option>
                                    ))}
                                    <option value={Math.max(...data.Years) + 1}>{Math.max(...data.Years) + 1}</option>
                                </select>
                            </div>
                            <div>
                                <label>{window.Dictionary.getTranslation(3699)}</label>
                                <select value={month} onChange={(e: any) => setMonth(e.target.value)}>
                                    <option value="1">1</option>
                                    <option value="2">2</option>
                                    <option value="3">3</option>
                                    <option value="4">4</option>
                                    <option value="5">5</option>
                                    <option value="6">6</option>
                                    <option value="7">7</option>
                                    <option value="8">8</option>
                                    <option value="9">9</option>
                                    <option value="10">10</option>
                                    <option value="11">11</option>
                                    <option value="12">12</option>
                                </select>
                            </div>
                        </div>
                    )}
                    <div className={`mfx-view container-size mfx-detailview disable-true ${props.ViewName}`}>
                        <div className="mfx-detailview-formelements">
                            {props.designer && props.designer.DesignElements && (
                                <>
                                    {Object.entries(props.designer.DesignElements)
                                        .sort((a: any, b: any) => {
                                            return a[1].Order > b[1].Order ? 1 : -1;
                                        })
                                        .map((key: any, idx) => {
                                            const designD = key[1];
                                            const keys = [key[1].Key];
                                            if (key[1].Key2) {
                                                keys.push(key[1].Key2);
                                            }
                                            return <GenericElement disableMode={true} customData={getValue(keys)} designData={{ ...designD }} key={idx + idx} index={idx} noLabel={false} />;
                                        })}
                                </>
                            )}
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}
