import elements from '../Common/AllElements';
import InputElement from '../../Elements/InputElement';
import Dropdown from './Dropdown';
import TextareaElement from '../../Elements/TextareaElement';
import VoiceRecorder from '../../Elements/VoiceRecorder';
import Headline from '../../Elements/Headline';
import SmallHeadline from './SmallHeadline';
import MainHeadline from '../../Elements/MainHeadline';
import Checkbox from '../../Elements/Checkbox';
import Quicklink from './Quicklink';
import Currency from 'Components/Lib/Elements/Currency';
import DoubleInputElement from 'Components/Lib/Elements/DoubleInputElement';

export default function GenericElement(props) {
    var designData = { ...props.designData, ReadOnly: props.readOnly };
    function generateElement(generic) {
        return (
            <div
                className="mfx-generic-element"
                style={JSON.stringify(props.index) === JSON.stringify(props.curElement) ? { border: '1px solid var(--hoverColor)' } : props.extraClass}
                onClick={e => {
                    if (props.onElementClicked) props.onElementClicked(e, props.index);
                }}
                draggable={props.onDragStart && props.onDragOver && props.onDragEnd ? true : false}
                id={designData.Key}
                onDragStart={e => props.onDragStart(e, props.index)}
                onDragOver={e => props.onDragOver(e, props.index)}
                onDragEnd={e => props.onDragEnd(e, props.index)}>
                <div>{generic}</div>
            </div>
        );
    }

    function generateElementGroup(generic) {
        return (
            <div onClick={e => props.onElementGroupClicked(e, { groupKey: props.index })} style={{ borderBottom: '1px solid #ccc', paddingBottom: '2rem' }}>
                {generic}
            </div>
        );
    }
    let HtmlTag = designData.Elementtype === 'text' ? (designData.Type ? designData.Type : 'p') : 'p';
    HtmlTag = elements.find(e => e.Elementtype === 'text').Types.find(e => e === HtmlTag) === undefined ? 'p' : HtmlTag;
    if (typeof designData === 'object') {
        switch (designData.Elementtype || props.data) {
            case 'elementgroup': {
                const group = designData;
                return (
                    <div className="mfx-grid-container">
                        {group.Data.map((el, elIndex) => {
                            return (
                                <div onClick={e => props.onElementGroupClicked(e, { groupKey: props.index })} key={`group-${elIndex}`} style={{ borderBottom: '1px solid #ccc' }} className={`mfx-elementgroup mfx-elementgroup-type-${group.Grouptype} mfx-col-${group.Groupcol}`}>
                                    {el.map((subel, gindex) => {
                                        return (
                                            <GenericElement
                                                key={gindex}
                                                index={{ groupKey: props.index, colKey: elIndex, rowKey: gindex }}
                                                designData={subel}
                                                data={props.data}
                                                disableMode={props.disableMode}
                                                onElementClicked={props.onElementClicked}
                                                onDragEnd={props.onDragEnd}
                                                onDragOver={props.onDragOver}
                                                onDragStart={props.onDragStart}
                                                curElement={props.curElement}
                                                extraClass={props.extraClass}
                                            />
                                        );
                                    })}
                                    <button onClick={e => props.onAddElement(e, props.index, elIndex)}>{window.Dictionary.getTranslation(14863)}</button>
                                </div>
                            );
                        })}
                    </div>
                );
            }
            case 'currency':
                return generateElement(<Currency designData={designData} disableMode={props.disableMode !== undefined ? props.disableMode : true} customerData={props.customerData} uniqueIndex={props.index} noLabel={props.noLabel || false} onChangeHandler={props.onChangeHandler} />);
            case 'input':
                return generateElement(<InputElement designData={designData} disableMode={props.disableMode !== undefined ? props.disableMode : true} customerData={props.customData} uniqueIndex={props.index} noLabel={props.noLabel || false} onChangeHandler={props.onChangeHandler} />);
            case 'doubleinput':
                return generateElement(<DoubleInputElement designData={designData} disableMode={props.disableMode !== undefined ? props.disableMode : true} customerData={props.customData} uniqueIndex={props.index} noLabel={props.noLabel || false} onChangeHandler={props.onChangeHandler} />);
            case 'textarea':
                return generateElement(<TextareaElement value={props.DefaultValue} designData={designData} disableMode={props.disableMode !== undefined ? props.disableMode : true} onChangeHandler={props.onChangeHandler} />);
            case 'dropdown':
                return generateElement(<Dropdown value={props.DefaultValue} designData={designData} disableMode={props.disableMode !== undefined ? props.disableMode : true} getDropdownValues={props.getDropdownValues} onChangeHandler={props.onChangeHandler} noLabel={props.noLabel || false} />);
            case 'voicetotext':
                return generateElement(<VoiceRecorder designData={designData} disableMode={props.disableMode !== undefined ? props.disableMode : true} customerData={props.customData} onChangeHandler={props.onChangeHandler} />);
            case 'checkbox':
                return generateElement(<Checkbox designData={designData} disableMode={props.disableMode !== undefined ? props.disableMode : true} customerData={props.customData} onChangeHandler={props.onChangeHandler} />);
            case 'quicklink':
                return generateElement(<Quicklink designData={designData} disableMode={props.disableMode !== undefined ? props.disableMode : true} customerData={props.customData} onChangeHandler={props.onChangeHandler} />);
            case 'headline':
                return generateElementGroup(<Headline designData={designData} />);
            case 'smallheadline':
                return generateElementGroup(<SmallHeadline designData={designData} />);
            case 'mainheadline':
                return generateElementGroup(<MainHeadline designData={designData} />);
            case 'empty':
                return generateElement(<div style={{ height: '70px', backgroundColor: 'var(--pageBg)', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>EMPTY ELEMENT</div>);
            case 'profiling':
                return (
                    <div
                        className="mfx-generic-element"
                        style={JSON.stringify(props.index) === JSON.stringify(props.curElement) ? { border: '1px solid var(--hoverColor)',gridColumn: '1/-1' } : {gridColumn: '1/-1'}}
                        onClick={e => {
                            if (props.onElementClicked) props.onElementClicked(e, props.index);
                        }}
                        draggable={props.onDragStart && props.onDragOver && props.onDragEnd ? true : false}
                        id={designData.Key}
                        onDragStart={e => props.onDragStart(e, props.index)}
                        onDragOver={e => props.onDragOver(e, props.index)}
                        onDragEnd={e => props.onDragEnd(e, props.index)}>
                        <div>
                            <div style={{ height: '70px', backgroundColor: 'var(--pageBg)', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>{window.Dictionary.getTranslation(13795)}</div>
                        </div>
                    </div>
                );
            case 'timerecordingtypes':
                return generateElement();
            default:
                return generateElement(<HtmlTag>{designData.Key ? window.Dictionary.getTranslation(designData.Key) : window.Dictionary.getTranslation(designData.DefaultValue) ? window.Dictionary.getTranslation(designData.DefaultValue) : '***!New Element!***'} </HtmlTag>);
        }
    }

    return <>{window.Dictionary.getTranslation(14864)}</>;
}
