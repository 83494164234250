/* eslint-disable jsx-a11y/no-autofocus */
import ModalPopup from 'Components/Lib/Common/ModalPopup';
import LocalStorage from '../../../Helpers/MFXLocalStorage';
import SessionStorage from '../../../Helpers/MFXSessionStorage';
import apiPost from '../Hooks/apiPost';
import URLS from '../../Lib/Utils/URLs.API';
import { useEffect, useState } from 'react';
import loadJsonData from '../Utils/loadJsonData';
import { getCookieValue, setLoginCookies } from 'Helpers/MFXCookie';
import Loading from '../Elements/Loading';
import { startAuthentication, startRegistration } from '@simplewebauthn/browser';
import { clearScreenDown } from 'readline';
import WikiInfo from '../Elements/WikiInfo';

export default function Login() {
    const [languages, setLanguages] = useState<null | any>();
    const [loading, setLoading] = useState(false);

    const [loginData, setLoginData] = useState({
        Username: '',
        Password: '',
        Database: (getCookieValue('MFX_DATABASE') as string) ?? '',
        Language: (getCookieValue('MFX_LANGUAGE') as string) ?? 'Deutsch',
    });
    const [databases, setDatabases] = useState([]);
    const [appSettings, setAppSettings] = useState(null as any);
    const [loginError, setLoginError] = useState({ Error: '' });
    const [ipAddress, setIpAddress] = useState('');

    //#region Load Basics
    useEffect(() => {
        LoadDatabases();
        LoadLanguages();
        LoadFido();
    }, []);

    useEffect(() => {
        var infos = (window as any).userInfos;
        console.log(infos);
        if (infos?.ConnectionKey) {
            setLoading(true);
            setUserData(infos);
            LocalStorage.createSession('UserName', infos.Username);
            setLoading(false);
        }
    }, [(window as any).userInfos]);

    function LoadDatabases() {
        loadJsonData(URLS.Databases).then((data: any) => {
            if (data.Meta.Success && data.Data) {
                setDatabases(data.Data.Databases);
                if (!data.Data.Databases.find(d => d.Name === loginData.Database)) {
                    setLoginData(prev => {
                        return { ...prev, Database: typeof data.Data.Databases[0] !== 'object' ? data.Data.Databases[0] : data.Data.Databases[0].Name };
                    });
                }
            }
        });
    }

    function LoadLanguages() {
        loadJsonData(URLS.Languages).then(data => {
            if (data.Meta.Success && data.Data) {
                setLanguages(data.Data as any);
            }
        });
    }

    function LoadFido() {
        loadJsonData(URLS.AppSettings).then((data: any) => {
            if (data.Meta.Success && data.Data) {
                setAppSettings(data.Data);
            }
        });
    }
    //#endregion Load Basics
    console.log(loginData);
    function checkForEnterHandler(e: any) {
        if (e.code === 'Enter' && (!appSettings?.OnlyFido2Login || appSettings?.OnlyFido2Login === null || appSettings.Fido2PlatformIPAddressRange === ipAddress)) LoginUser();
        (window as any).userInfos = e.target.value;
    }

    async function LoginUser() {
        setLoading(true);
        if (loginData.Username && loginData.Password && loginData.Database) {
            console.log('LoginUser');
            try {
                let login = await apiPost(URLS.Login, loginData);
                if (login && login.Success) {
                    setUserData(login);
                    LocalStorage.createSession('UserName', loginData.Username);
                } else {
                    console.log(login);
                    setLoginError({ Error: 'Error: ' + login.Meta.ErrorMessages[0].Message });
                    logger.error(login.Meta.ErrorMessages[0].Message);
                    setLoading(false);
                }
            } catch (e: any) {
                console.log(e);
                setLoginError({ Error: window.Dictionary?.getTranslation(3316) });
                logger.error(window.Dictionary?.getTranslation(3316));
                setLoading(false);
            }
        } else {
            setLoginError({ Error: window.Dictionary?.getTranslation(3316) });
            logger.error(window.Dictionary?.getTranslation(3316));
            setLoading(false);
        }
    }

    function setUserData(login: any) {
        loadJsonData('/' + login.ConnectionKey + URLS.GetEmployeeInfo)
            .then(data => {
                if (!data?.Meta?.Success) {
                    alert('Error: Etwas ist falsch gelaufen beim abrufen der Employee Info');
                    return;
                }
                LocalStorage.createSession('UserData', data.Data);

                loadJsonData('/' + login.ConnectionKey + URLS.GetCurrentLayoutRole)
                    .then((data: any) => {
                        if (data.Meta.Success) LocalStorage.createSession('UserRole', data.Data.LayoutName);
                        else LocalStorage.createSession('UserRole', 'default');

                        const key = LocalStorage.createDBStorage({ key: login.ConnectionKey, db: loginData.Database }, { primaryId: -1, primaryType: 'start', context: {} }, '/processes/dashboard');

                        setLoginCookies(loginData.Username.replaceAll('ß', 'SS'), loginData.Language, loginData.Database);

                        window.userData = loginData;
                        var link = '/' + key;
                        if (SessionStorage.getData('forwardLink') && SessionStorage.getData('forwardLink').includes(key)) {
                            link = SessionStorage.getData('forwardLink');
                        }
                        SessionStorage.createSession('forwardLink', '');
                        document.location.href = link;
                    })
                    .catch(e => {
                        logger.information(e);

                        LocalStorage.createSession('UserRole', 'default');
                    });
            })
            .catch(e => {
                logger.information(e);
            });
    }
    //#region PassKeys
    async function registerPassKeys() {
        if (loginData.Username && loginData.Database) {
            try {
                const settings = {
                    method: 'POST',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ Username: loginData.Username, Password: loginData.Password, Database: loginData.Database }),
                };
                //let login = await apiPost(URLS.MakeCredentialOptions, {Username : loginData.Username, Database : loginData.Database});
                let resp = await fetch(URLS.MakeCredentialOptions, settings);
                console.log(resp);
                let attResp;
                try {
                    // Pass the options to the authenticator and wait for a response
                    attResp = await startRegistration(await resp.json());
                } catch (error: any) {
                    // Some basic error handling
                    if (error.name === 'InvalidStateError') {
                        console.log('Error: Authenticator was probably already registered by user');
                    } else {
                        console.log(error);
                    }

                    throw error;
                }

                // POST the response to the endpoint that calls
                // @simplewebauthn/server -> verifyRegistrationResponse()
                const verificationResp = await fetch('/verify-registration', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(attResp),
                });

                // Wait for the results of verification
                const verificationJSON = await verificationResp.json();

                // Show UI appropriate for the `verified` status
                if (verificationJSON && verificationJSON.Status.ok) {
                    console.log('Success!');
                } else {
                    console.log(`Oh no, something went wrong! Response: <pre>${JSON.stringify(verificationJSON)}</pre>`);
                }
            } catch (error) {
                // Some basic error handling
                console.log(error);
                throw error;
            }
        } else {
            alert('Username & DB angeben');
        }
    }

    async function startPassKeys() {
        if (loginData.Username && loginData.Database) {
            try {
                const settings = {
                    method: 'POST',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ Username: loginData.Username, Database: loginData.Database }),
                };
                //let login = await apiPost(URLS.MakeCredentialOptions, {Username : loginData.Username, Database : loginData.Database});
                let resp = await fetch(URLS.AssertionOptions, settings);
                let asseResp;

                try {
                    // Pass the options to the authenticator and wait for a response
                    asseResp = await startAuthentication(await resp.json());
                } catch (error) {
                    // Some basic error handling
                    console.log(error);
                    throw error;
                }

                // POST the response to the endpoint that calls
                // @simplewebauthn/server -> verifyAuthenticationResponse()
                const verificationResp = await fetch('/verify-authentication', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(asseResp),
                });

                // Wait for the results of verification
                const verificationJSON = await verificationResp.json();

                // Show UI appropriate for the `verified` status
                if (verificationJSON && verificationJSON.Success) {
                    console.log('Success!');
                    console.log(verificationJSON);
                    setUserData(verificationJSON);
                } else {
                    console.log(`Oh no, something went wrong! Response: <pre>${JSON.stringify(verificationJSON)}</pre>`);
                }
            } catch (e: any) {
                //setLoginError({ Error: window.Dictionary?.getTranslation(3316) });
                setLoading(false);
            }
        } else {
            alert('Username & DB angeben');
        }
    }
    //#endregion PassKeys

    function loginAzura() {
        document.location = `/externalLogin?db=${loginData.Database}&type=azure&lang=${loginData.Language}`;
    }

    return (
        <ModalPopup modalClass="login">
            {loading && <Loading />}
            {!loading && (
                <>
                    <div className='top-right-own'>
                    <WikiInfo url={'login'}></WikiInfo>
                    </div>
                    <h2 style={{ textAlign: 'center' }}>{window.Dictionary?.getTranslation(13578)}</h2>
                    {!appSettings?.OnlyOAuthLogin && (
                        <div className="mfx-input-element">
                            {loginError.Error && <label className="error"> {loginError.Error}</label>}
                            <input
                                autoFocus
                                type="text"
                                name="username"
                                defaultValue={loginData.Username}
                                onChange={e => setLoginData({ ...loginData, Username: e.target.value.replaceAll('ß', '\u9999').toUpperCase().replaceAll('\u9999', 'ß') })}
                                placeholder={window.Dictionary?.getTranslation(889)}
                            />
                        </div>
                    )}
                    {!appSettings?.OnlyOAuthLogin && (
                        <div className="mfx-input-element">
                            <input type="password" name="pwd" defaultValue={loginData.Password} onKeyUp={checkForEnterHandler} onChange={e => setLoginData({ ...loginData, Password: e.target.value })} placeholder={window.Dictionary?.getTranslation(305)} />
                        </div>
                    )}
                    <div className="mfx-dropdown-element">
                        <select name="database" value={loginData.Database} onChange={e => setLoginData({ ...loginData, Database: e.target.value })}>
                            {databases.map((d: any, idx) => (
                                <option key={idx} value={d.Name}>
                                    {d.Name}
                                </option>
                            ))}
                        </select>
                    </div>

                    <div className="mfx-dropdown-element">
                        <select name="language" value={loginData.Language} onChange={e => setLoginData({ ...loginData, Language: e.target.value })}>
                            {languages &&
                                languages.Languages.map((key: string, idx: number) => {
                                    return (
                                        <option key={idx} value={key}>
                                            {key}
                                        </option>
                                    );
                                })}
                        </select>
                    </div>
                    {appSettings !== null && !appSettings?.OnlyOAuthLogin && (!appSettings?.OnlyFido2Login || appSettings?.OnlyFido2Login === null) && <button onClick={() => LoginUser()}>{window.Dictionary?.getTranslation(3301)}</button>}
                    {appSettings !== null && !appSettings?.OnlyOAuthLogin && (
                        <button className="passkeys-login" onClick={() => startPassKeys()}>
                            <i className="icon-fingerprint-big"></i>
                            {window.Dictionary?.getTranslation(14604)}
                        </button>
                    )}
                    {appSettings !== null && !appSettings?.OnlyOAuthLogin && (!appSettings?.OnlyFido2Login || appSettings?.OnlyFido2Login === null || appSettings.Fido2RegisterEnabled) && (
                        <button className="passkeys-login border" onClick={() => registerPassKeys()}>
                            <i className="icon-fingerprint-big"></i>
                            {window.Dictionary?.getTranslation(14605)}
                        </button>
                    )}
                    {(databases.find((d: any) => d.Name === loginData.Database) as any)?.AzureAuth && (
                        <button className="passkeys-login border" onClick={() => loginAzura()}>
                            <i className="icon-fingerprint-big"></i>
                            AzureAuth
                        </button>
                    )}
                </>
            )}
        </ModalPopup>
    );
}
