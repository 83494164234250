import icons from '../../../../Data/Designer/icons.json';

export default function IconPicker(props) {
    return (
        <div class="mfx-settings-accordion">
        <div id="mfx-generic-view-menu">
            <div className="mfx-designer-icon-radios">
                <label>
                    <input type="radio" value="" name="design-icons" checked={props.icon ? false : true} onChange={e => props.changeIcon(null)} /> {window.Dictionary.getTranslation(15223)}
                </label>
                {
                    icons.map((icon, idx) => {
                        return (
                            <label key={idx}>
                                <input type="radio" value={icon} name="design-icons" checked={props.icon === icon ? true : false} onChange={e => props.changeIcon(e.target.value)} />
                                {props.icon === icon && <i style={{ backgroundColor: 'blue' }} className={icon}></i>}
                                {props.icon !== icon && <i className={icon}></i>}
                            </label>
                        );
                    })
                }
            </div>
        </div>
        </div>
    );
}
