/**
 * Renders only the Savebutton
 * @returns {JSX.Element} The basic Savebutton.
 */

const Button = props => {
    return (
        <>
            <button type="button" className={`mfx-button mfx-button-type-icon save-button-designer ${props.important ? 'important' : ''}`} id={props.id} onClick={props.onClickHandler}>
                <i className={props.iconClass}></i>
                {props.dll && <span>{window.Dictionary.getTranslation(props.dll)}</span>}
            </button>
        </>
    );
};

export default Button;
