/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/*
 * Function to render Tabledata
 * Merges CustomData and DesignData
 */

import { Link } from 'react-router-dom';
import GenericElement from './GenericElement';
import sessionedLink, { mainTabLink } from './sessionedLinks';

interface IGenericViewTdProperties {
    data: any;
    designData: any;
    index: string; //tr count
    disableMode: boolean;
    tableHead: any;
    noLabel?: boolean;
    detailType?: string;
    noForm?: boolean;
    mainlink: boolean;
    idx: number;
    changeData(value: IValueObject): void;
    onClickHandler(link: any): void;
    onAddNewTab(data, index, detailType): void;
}

export interface IValueObject {
    type: string;
    newValue: string;
    oldValue: string;
    idx?: number | string;
}

export function GenericTds(props: IGenericViewTdProperties): JSX.Element {
    const renderCustomerTds = (data: any, designData: any, index: string, disableMode: boolean, tableHead: any, detailType: string = '', noForm: boolean = false, mainlink = false): any => {
        var link = '';
        link = getCurLink(data, detailType, mainlink);
        // if(detailType && detailType !== 'customer') link = getCurLink(data, detailType, mainlink); // sets right link
        if (detailType === 'customer') detailType = '';

        return (
            <>
                {Object.entries(tableHead)
                    .sort((a: any, b: any) => (a[1].Order > b[1].Order ? 1 : -1))
                    .map((attributes: any, idxObj) => {
                        const keys = (attributes[1].Key as string).split('-');
                        if (keys[0] === 'Data') keys.splice(0, 1);
                        let value = data;
                        let designD = { ...attributes[1] };
                        // Exception-Elements that need all data
                        if (designD.Elementtype !== 'download') {
                            keys.forEach(key => {
                                value = value ? value[key] : '';
                            });
                        }
                        let uniqueIndex = index + '-' + attributes[0];
                        let newDesignD = { ...designD };
                        if (newDesignD.Elementtype === 'checkbox' || designD.Elementtype === 'currency') newDesignD.Label = '';
                        return (
                            <>
                                {detailType !== '' && !mainlink && (
                                    <td key={'td' + uniqueIndex + idxObj} className={`type-${designD.Elementtype} key-${designD.Key}`} column-name={`${designD.Key}`} column-value={`${value ?? ''}`}>
                                        <Link to={link}>
                                            {
                                                <GenericElement
                                                    customData={value}
                                                    designData={newDesignD}
                                                    key={'el-' + uniqueIndex + idxObj}
                                                    index={uniqueIndex}
                                                    disableMode={disableMode}
                                                    noLabel={true}
                                                    onChangeGenericElement={(valueObject: IValueObject) => props.changeData({ ...valueObject, idx: index })}
                                                    underlined={attributes[1].Clickable ?? false}
                                                    noForm={noForm}
                                                />
                                            }
                                        </Link>
                                    </td>
                                )}

                                {(!detailType || mainlink) && (
                                    <td
                                        key={'td' + uniqueIndex + idxObj}
                                        className={`type-${designD.Elementtype} key-${designD.Key}`}
                                        column-name={`${designD.Key}`} column-value={`${value}`}
                                        onClick={e => {
                                            if (props.onClickHandler && detailType) props.onClickHandler(link);
                                            else if (e.detail === 2 && props.onAddNewTab) props.onAddNewTab(data, props.idx, 'customer');
                                        }} >
                                        {
                                            <GenericElement
                                                customData={value}
                                                designData={designD}
                                                key={'el-' + uniqueIndex + idxObj}
                                                index={uniqueIndex}
                                                disableMode={disableMode}
                                                noLabel={true}
                                                onChangeGenericElement={(valueObject: IValueObject) => props.changeData({ ...valueObject, idx: index })}
                                                underlined={attributes[1].Clickable ?? false}
                                                noForm={noForm}
                                            />
                                        }
                                    </td>
                                )}
                            </>
                        );
                    })}
            </>
        );
    };

    return renderCustomerTds(props.data, props.designData, '' + props.index, props.disableMode, props.tableHead, props.detailType, props.noForm, props.mainlink);
}

function getCurLink(data, detailType, mainlink) {
    let link = '';
    let detailIndex = data.Index;
    let detailCustomer = data.CustomerNumber;
    let hasCustomer = false;

    if (detailType === 'offer' && data.Number) {
        detailIndex = data.Number;
        hasCustomer = true;
    } else if (data.CustomerNumber) {
        hasCustomer = true;
    } else if (data.Process?.CustomerSupplierNumber) {
        detailCustomer = data.Process.CustomerSupplierNumber;
        hasCustomer = true;
    } else if (data.CustomerSupplierNumber) {
        detailCustomer = data.CustomerSupplierNumber;
        hasCustomer = true;
    }
    let processNU = data.ProcessIndex ? '&process=' + data.ProcessIndex : '';
    if (hasCustomer) {
        if (!mainlink) link = sessionedLink(`/processes/detailview?type=${detailType}&index=${detailIndex}&customer=${detailCustomer}${processNU}`);
        else link = mainTabLink(`/processes/detailview?type=${detailType}&index=${detailIndex}&customer=${detailCustomer}&search=true${processNU}`);
    } else {
        if (!mainlink) link = sessionedLink(`/processes/detailview?type=${detailType}&index=${detailIndex}${processNU}`);
        else link = mainTabLink(`/processes/detailview?type=${detailType}&index=${detailIndex}&search=true${processNU}`);
    }
    return link;
}

/*if(detailType) link = sessionedLink(`/processes/detailview?type=${detailType}&index=${data.Index}`);
        if(detailType==='offer' && data.Number)
            link = sessionedLink(`/processes/detailview?type=${detailType}&index=${data.Number}&customer=${data.CustomerNumber}`);
        else if(data.CustomerNumber)
            link = sessionedLink(`/processes/detailview?type=${detailType}&index=${data.Index}&customer=${data.CustomerNumber}`);
        else if(data.Process?.CustomerSupplierNumber)
            link = sessionedLink(`/processes/detailview?type=${detailType}&index=${data.Index}&customer=${data.Process.CustomerSupplierNumber}`);
        else if(data.CustomerSupplierNumber)
            link = sessionedLink(`/processes/detailview?type=${detailType}&index=${data.Index}&customer=${data.CustomerSupplierNumber}`);*/
