export function getTodaysDate(hour, type) {
    let date = new Date();
    let month = new Date().getMonth() + 1;

    if (type === 'date') return date.getFullYear() + '-' + addZero(month) + '-' + addZero(date.getDate());
    else {
        let nhour = date.getHours() + hour;
        nhour = nhour <= 24 ? nhour : nhour - 24;
        return date.getFullYear() + '-' + addZero(month) + '-' + addZero(date.getDate()) + 'T' + addZero(nhour) + ':' + addZero(date.getMinutes());
    }
}

function addZero(number) {
    return number > 9 ? number : '0' + number;
}

export function changeTimeOfDate(date, newTime) {
    
    date = new Date(date);
    if(!date || !newTime) return null;
    date.setHours(newTime.split(':')[0], newTime.split(':')[1], 0);
    return date;
}
export function getTimeFromDate(date) {
    return date.getHours().toLocaleString('de-DE', { minimumIntegerDigits: 2, useGrouping: false }) + ':' + date.getMinutes().toLocaleString('de-DE', { minimumIntegerDigits: 2, useGrouping: false });
}

export function toDateFormat(input) {
    if (input === null || input === undefined) {
        return input;
    }
    if (typeof input === 'string') {
        return input.substring(0, 10);
    }
    let date = new Date(input);
    if (isNaN(date.getTime())) date = new Date(null);
    return date.getFullYear() + '-' + addZero(date.getMonth() + 1) + '-' + addZero(date.getDate());
}

export function toDateTimeFormat(input) {
    if (input === null || input === undefined) {
        return input;
    }
    if (typeof input === 'string') {
        return input.substring(0, 16);
    }
    let date = new Date(input);
    if (isNaN(date.getTime())) {
        date = new Date(null);
    }
    date = date.toJSON().slice(0, 16);
    return date;
}

export function formatDate(input, format) {
    var date = '';
    date += input.getDate() + '.' + (input.getMonth() + 1 < 10 ? '0' + (input.getMonth() + 1) : input.getMonth() + 1) + '.' + input.getFullYear();
    if (format !== 'Date') date += ' ' + input.getHours() + ':' + input.getMinutes().toLocaleString('de-DE', { minimumIntegerDigits: 2, useGrouping: false }) + ':' + input.getSeconds().toLocaleString('de-DE', { minimumIntegerDigits: 2, useGrouping: false });
    return date;
}

export function formatedToInput(input) {
    var date = '';
    if (input) {
        date += input.substring(6, 11) + '-' + input.substring(3, 5) + '-' + input.substring(0, 2);
    }
    return date;
}
export function getDayShort(input) {
    var date = new Date(input)?.toLocaleString('de-de', { weekday: 'short' });
    return date;
}

export function minuteToHHMM(input) {
    var res = Math.trunc(input / 60).toLocaleString('de-DE', { minimumIntegerDigits: 2, useGrouping: false }) + ':' + (input % 60).toLocaleString('de-DE', { minimumIntegerDigits: 2, useGrouping: false });
    return res;
}

export function toISOOffset(input) {
    if (!input) return null;
    var date = new Date(input);
    if (!isValidDate(date)) {
        return null;
    }
    var localISOTime = new Date(date - date.getTimezoneOffset() * 60000).toISOString().slice(0, -1);
    return localISOTime;
}
export function workingTimeRounding(input) {
    var rounding = 11;
    var minutes = input.getMinutes();
    input.setHours(input.getHours(), minutes % 15 >= rounding ? minutes + (15 - (minutes % 15)) : minutes - (minutes % 15), 0);
    return input;
}

export function getDurationInMinutesABS(date, date2) {
    return date && date2 ? Math.abs(Math.round((new Date(date2).getTime() - new Date(date).getTime()) / 60000)) : 0;
}

export function getDurationInMinutes(date, date2) {
    return date && date2 ? Math.round((new Date(date2).getTime() - new Date(date).getTime()) / 60000) : 0;
}

export function isValidDate(d) {
    return d instanceof Date && !isNaN(d);
}
