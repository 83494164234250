/* eslint-disable jsx-a11y/media-has-caption */
import { Navigate, Route, Routes } from 'react-router-dom';
import Processes from './Routes/processes';
import Header from 'Components/Layouts/Header';
import DesignerHeader from 'Components/Lib/Designer/Layouts/Header';

import Login from 'Components/Lib/Common/Login';
import { VerifyConnectionKey } from 'Components/Lib/Common/VerifyConnectionKey';
import StorageNavigate from 'Components/Lib/Common/LocalStorageNavigate';
import LocalStorage from 'Helpers/MFXLocalStorage';
import SessionStorage from 'Helpers/MFXSessionStorage';
import PLXLogger from 'Components/Lib/Utils/MFXLogger';

import './App.scss';
import { getCookie, getCookieValue } from 'Helpers/MFXCookie';
import URLS from './Components/Lib/Utils/URLs.API';
import loadJsonData from 'Components/Lib/Utils/loadJsonData';
import Dictionary from 'Components/Lib/Utils/Dictionary';
import Base_lang from 'Data/base_lang.json';
import Process_lang from 'Data/process_lang.json';
import { useEffect, useState } from 'react';
import LocalStorageNavigate from 'Helpers/LocalStorageNavigate';
import Pages from 'Routes/Pages';
import Views from 'Routes/Views';
import DetailViews from 'Routes/DetailViews';
import Stylings from 'Routes/Stylings';
import Menus from 'Routes/Menus';
import Settings from 'Routes/Settings';
import sound from 'Styles/audio/offline.mp3';
import ModalPopup from 'Components/Lib/Common/ModalPopup';
/**
 * The main application for PLX.Suite.CRM
 *
 * @returns {JSX.Element} main application, routed
 */

//enterForDebugging
//window.urlPrefix = 'http://localhost:8080'
window.urlPrefix = '';

export default function MainApplication(): JSX.Element {
    window.logger = new PLXLogger();
    const localStorageRoutePath = LocalStorage.getCurrentPath() || 'NO_SESSION';
    let routes: JSX.Element;
    const [translationLoaded, setTranslationLoaded] = useState(false);
    const [showDesigner, setShowDesigner] = useState(false);

    window.addEventListener('online', () => {
        let beat = new Audio();
        beat.src = sound;
        beat.play();
        console.log('Back online');
    });
    window.addEventListener('offline', () => {
        let beat = new Audio();
        beat.src = sound;
        beat.play();
        setOffline(true);
        console.log('Became offline');
    });

    useEffect(() => {
        if (getCookie('MFX_LANGUAGE')) {
            loadJsonData(URLS.LanguageJson + '?language=' + getCookieValue('MFX_LANGUAGE'))
                .then(data => {
                    window.Dictionary = new Dictionary([(data as any).Data.LanguageEntries]);
                    setTranslationLoaded(true);
                })
                .catch(error => logger.debug(error));
        } else if (!getCookie('MFX_LANGUAGE')) {
            window.Dictionary = new Dictionary([Base_lang, Process_lang]); // eslint-disable-line
            setTranslationLoaded(true);
        }
        if (LocalStorage?.getConnectionKey()) {
            loadJsonData('/' + LocalStorage?.getConnectionKey() + URLS.GetEmployeeInfo)
                .then((data: any) => {
                    setShowDesigner(data?.Data?.IsAdministrator ?? false);
                })
                .catch(e => {
                    console.log(e);
                });
        }
        if (LocalStorage.getData('OfferSigningForward')) {
            var link = LocalStorage.getData('OfferSigningForward');
            LocalStorage.removeSession('OfferSigningForward');
            LocalStorage.createSession('OfferSigningMessage', true);
            window.location.href = link;
        }
    }, []);
    if (!getCookie('MFX_Login') || localStorageRoutePath === 'NO_SESSION' || !LocalStorage.hasTabInDB(localStorageRoutePath) || localStorageRoutePath === 'designer_NO_SESSION') {
        if (!LocalStorage.getData('OfferSigningForward')) LocalStorage.removeAllLocals(false);
        logger.debug('No SESSION => enforce: /login');
        if (SessionStorage.getData('Logout') !== window.location.href && window.location.href.includes('MFXDB')) {
            SessionStorage.createSession('forwardLink', window.location.href);
        }
        routes = <></>;
        routes = (
            <Routes>
                <Route path="/">
                    <Route index element={<Navigate to="/login" />} />
                    <Route path="*" element={<Navigate to="/login" />} />
                    <Route path="/login" element={<Login />} />
                </Route>
            </Routes>
        );
    } else {
        const childRoutes = (
            <>
                <Route index element={<StorageNavigate to="/processes/dashboard" />} />
                <Route path="profile" element={<StorageNavigate to="/common/profile" />} />
                <Route path="processes" children={Processes} />
            </>
        );
        const designerChildRoutes = (
            <>
                <Route index element={<LocalStorageNavigate to="/pages/dashboard" />} />
                <Route path="pages" children={Pages} />
                <Route path="views" children={Views} />
                <Route path="detailviews" children={DetailViews} />
                <Route path="addviews" children={DetailViews} />
                <Route path="stylings" children={Stylings} />
                <Route path="menu" children={Menus} />
                <Route path="settings" children={Settings} />
            </>
        );
        routes = (
            <Routes>
                <Route path="/" element={<VerifyConnectionKey />}>
                    <Route path="*" element={<>View not implemented yet</>} />
                    <Route path="/login" element={<StorageNavigate to="/processes/dashboard" />} />
                    <Route path={localStorageRoutePath} children={childRoutes} element={<VerifyConnectionKey />} />
                </Route>
                <Route path="/designer" element={<VerifyConnectionKey />}>
                    {showDesigner && (
                        <>
                            <Route path={LocalStorage.getDBData().prefix} children={designerChildRoutes} element={<VerifyConnectionKey />} />
                            <Route path="*" element={<>Designer View not implemented yet</>} />
                        </>
                    )}
                    {!showDesigner && <Route path="*" element={<>Not authorized.</>} />}
                </Route>
            </Routes>
        );
    }
    const [offline, setOffline] = useState(false);
    return (
        <>
            {window.Dictionary && !offline && (
                <>
                    {window.location.pathname.split('/')[1] !== 'designer' && <Header />}
                    {window.location.pathname.split('/')[1] === 'designer' && <DesignerHeader />}
                    {translationLoaded === true && routes}
                </>
            )}
            {offline && (
                <ModalPopup closeIcon={true} closePopup={e => setOffline(false)}>
                    <div className={`success-${true}`}>
                        <div className="save-message">
                            <p dangerouslySetInnerHTML={{ __html: window.Dictionary.getTranslation('You are offline') }} />
                        </div>
                        <div className="save-modal-buttons">
                            <button className="mfx-button" onClick={e => setOffline(false)}>
                                {'OK'}
                            </button>
                        </div>
                    </div>
                </ModalPopup>
            )}
        </>
    );
}
