/* eslint-disable jsx-a11y/label-has-associated-control */
import { useEffect, useState } from 'react';
import GenericElement from '../Utils/GenericElement';
import loadJsonData from '../Utils/loadJsonData';
import LocalStorage from 'Helpers/MFXLocalStorage';
import URLS from '../Utils/URLs.API';
import Loading from 'Components/Lib/Elements/Loading';
import { toDateFormat } from '../../../Helpers/dateHelper';
import InputElement from '../Elements/InputElement';
import loadJsonPost from '../Utils/jsonPost';
import Editbutton from '../Elements/Editbutton';

export function ExpenseReportPositionsView(props: any) {
    const [data, setData] = useState(null as any);
    const [loading, setLoading] = useState(true);
    const [fromDate, setFromDate] = useState(startOfMonth(new Date()));
    const [toDate, setToDate] = useState(endOfMonth(new Date()));
    const [km, setKm] = useState(0);
    const [kmPrice, setKmPrice] = useState(0);
    const [dayMoney, setDayMoney] = useState(0);
    const [pocketExpense, setPocketExpense] = useState(0);

    useEffect(() => {
        loadData();
    }, [fromDate, toDate]);

    function addParamToUrl(index) {
        var params = window.location.href.split('?')[1];
        if (params) {
            var newParams = params.split('&').filter(p => !p.includes('expensereport'));
            newParams.push('expensereport=' + index);
            params = newParams.join('&');
        } else {
            params = 'expensereport=' + index;
        }
        var newurl = window.location.href.split('?')[0] + (params ? '?' + params : '');
        if (window.location.href !== newurl) window.history.replaceState({ path: newurl }, '', encodeURI(newurl));
    }

    function loadData() {
        setLoading(true);
        let start = toDateFormat(fromDate);
        let end = toDateFormat(toDate);
        loadJsonData('/' + LocalStorage.getConnectionKey() + URLS.GetTravelExpenses + '?Data.Filter.BeginDate=' + start + '&Data.Filter.EndDate=' + end).then((data: any) => {
            if (data.Meta.Success && data.Data && data.Data.TravelExpenseData) {
                console.log(data);

                setData(data.Data.TravelExpenseData);
                addParamToUrl(data.Data.TravelExpenseData[0].Index);

                var kmVal = 0;
                var kmPriceVal = 0;
                var dayMoneyVal = 0;
                var pocketExpenseVal = 0;

                data.Data.TravelExpenseData.forEach(expense => {
                    kmVal += expense.Kilometers >= 0 ? expense.Kilometers : 0;
                    kmPriceVal += expense.KilometerCostsTotal >= 0 ? expense.KilometerCostsTotal : 0;
                    dayMoneyVal += expense.DayMoney >= 0 ? expense.DayMoney : 0;
                    pocketExpenseVal += expense.PocketExpenses >= 0 ? expense.PocketExpenses : 0;
                });
                setKm(kmVal);
                setKmPrice(kmPriceVal);
                setDayMoney(dayMoneyVal);
                setPocketExpense(pocketExpenseVal);
            }
            setLoading(false);
        });
    }

    function endOfMonth(date) {
        return new Date(date.getFullYear(), date.getMonth() + 1, 0, 12, 0, 0);
    }

    function startOfMonth(date) {
        return new Date(date.getFullYear(), date.getMonth(), 1, 12, 0, 0);
    }

    function onChange(id, key, newValue) {
        if ((key === 'Departure' && data[id].Arrival && new Date(newValue) > new Date(data[id].Arrival)) || (key === 'Arrival' && data[id].Departure && new Date(data[id].Departure) > new Date(newValue))) {
            console.log('Arrival > Departure');
            return;
        }
        data[id][key] = newValue;
        if (key === 'Date') {
            if (data[id].Departure) {
                var newDeparture = new Date(newValue);
                data[id].Departure = new Date(data[id].Departure);
                newDeparture.setHours(data[id].Departure.getHours(), data[id].Departure.getMinutes(), data[id].Departure.getSeconds());
            }
            if (data[id].Arrival) {
                var newArrival = new Date(newValue);
                data[id].Arrival = new Date(data[id].Arrival);
                newArrival.setHours(data[id].Arrival.getHours(), data[id].Arrival.getMinutes(), data[id].Arrival.getSeconds());
            }
        } else if (key === 'Kilometers') {
            data[id].KilometerCostsTotal = data[id].Kilometers * data[id].KilometerCostsFee;
        }
        setData(data.map(o => ({ ...o })));
    }

    function save(e) {
        setLoading(true);
        e.preventDefault();
        var body = {
            Data: {
                TravelExpenseData: data,
            },
        };
        loadJsonPost('/' + LocalStorage.getConnectionKey() + URLS.UpdateTravelExpenses, body).then((data: any) => {
            loadData();
        });
    }

    return (
        <>
            {loading && <Loading />}
            {data && !loading && (
                <>
                    <div className="mfx-action-buttons">
                        <Editbutton saveChangeHandler={e => save(e)} />
                    </div>
                    <div className="mfx-table-container">
                        <div className="mfx-from-to-date-wrapper">
                            <InputElement id="date-for" customerData={fromDate} designData={{ Type: 'date', Defaultvalue: fromDate, Key: 'ChangeDate', Label: 1586 }} disableMode={false} onChangeHandler={e => setFromDate(new Date(e.newValue))}></InputElement>
                            <InputElement customerData={toDate} designData={{ Type: 'date', Defaultvalue: toDate, Key: 'ChangeDate', Label: 1587 }} disableMode={false} onChangeHandler={e => setToDate(new Date(e.newValue))}></InputElement>
                        </div>
                        <table className="mfx-table">
                            <tr>
                                {props.designer?.TableElements &&
                                    props.designer?.TableElements[0] &&
                                    Object.entries(props.designer.TableElements[0]).map((designer: any, idx) => {
                                        return <th>{window.Dictionary.getTranslation(designer[1].Label)}</th>;
                                    })}
                            </tr>
                            {data.map((d, id) => {
                                return (
                                    <tr
                                        onClick={e => {
                                            addParamToUrl(d.Index);
                                        }}>
                                        {props.designer?.TableElements &&
                                            props.designer?.TableElements[0] &&
                                            Object.entries(props.designer.TableElements[0]).map((designer: any, idx) => {
                                                var value = d[designer[1].Key];
                                                return (
                                                    <td>
                                                        <GenericElement customData={value} designData={{ ...designer[1] }} key={id + idx} index={idx} onChangeGenericElement={e => onChange(id, e.designData.Key, e.newValue)} />
                                                    </td>
                                                );
                                            })}
                                    </tr>
                                );
                            })}
                        </table>
                        <div className="expense-report-sums">
                            <div className="expense-report-sum">
                                <label>{window.Dictionary.getTranslation(6085)}</label>
                                <input disabled type="number" value={dayMoney}></input>
                                <span>EUR</span>
                            </div>
                            <div className="expense-report-sum">
                                <label>{window.Dictionary.getTranslation(6086)}</label>
                                <input disabled type="number" value={pocketExpense}></input>
                                <span>EUR</span>
                            </div>
                            <div className="expense-report-sum">
                                <label>{window.Dictionary.getTranslation(6088)}</label>
                                <input disabled type="number" value={km} style={{ marginLeft: '50px' }}></input>
                                <input disabled type="number" value={kmPrice} style={{ marginLeft: '50px' }}></input>
                                <span>EUR</span>
                            </div>
                            <div className="expense-report-sum complete-sum">
                                <label>{window.Dictionary.getTranslation(6089)}</label>
                                <input disabled type="number" value={dayMoney + pocketExpense + kmPrice}></input>
                                <span>EUR</span>
                            </div>
                        </div>
                    </div>
                </>
            )}
        </>
    );
}
