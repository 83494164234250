/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { viewActions } from '../../../../Helpers/Stores/viewStore';
import { RootState } from '../GenericViewEditor';
import Button from '../Elements/Button';
import Quicklinks from '../../../../Data/Designer/Defaultviews/quicklinks.json';
import Explorers from '../../../../Data/Designer/explorers.json';

interface IGenericPageMenuProps {
    data: any;
    index: any;
    onChangeHandler(settings: object): void;
    onSelectElement(key: any, e: any): void;
}

export default function FreeElementsMenu(props: IGenericPageMenuProps) {
    const dispatch = useDispatch();
    const curElement = useSelector((state: RootState) => (props?.index?.colKey >= 0 ? (state.viewData.CustomElements[props?.index?.groupKey] as any)?.Data[props?.index?.colKey][props?.index?.rowKey] : (state.viewData.CustomElements[props?.index?.groupKey] as any)?.Data));

    const [settingsSelected, setSettingsSelected] = useState({
        default: false,
        element: false,
        elementgroup: false,
        selectmode: true,
    });
    function showSettings(type: string) {
        let newSettingsObject = { ...settingsSelected } as any;
        Object.keys(newSettingsObject).forEach(settings => {
            if (settings === type) newSettingsObject[type] = !newSettingsObject[type];
            else newSettingsObject[settings] = false;
        });

        setSettingsSelected(newSettingsObject);
    }
    function changeElement(value: any, target: string) {
        dispatch(viewActions.changeElement({ value: value, target: target, key: props.index }));
    }

    function changeElementGroup(value: any, target: string) {
        dispatch(viewActions.changeElementGroup({ value: value, target: target, key: props.index }));
        props.onSelectElement(null, { groupKey: props.index.groupKey });
    }
    function removeElementGroup() {
        dispatch(viewActions.removeElementGroup(props.index.groupKey));
        props.onSelectElement(null, null);
    }
    function changeIcon(data: any) {
        changeElementGroup(data.value, data.type);
    }
    console.log(curElement);
    return (
        <div id="mfx-generic-view-menu">
            {curElement && (
                <>
                    <h2>{window.Dictionary.getTranslation(1109)}</h2>
                    {props.index?.colKey >= 0 && (
                        <div className="mfx-settings-accordion">
                            <select
                                name="tag-selection"
                                value={curElement.Type ?? 'p'}
                                onChange={e => {
                                    if (e.target.value === 'quicklink') {
                                        dispatch(viewActions.changeElement({ target: 'Elementtype', value: 'quicklink', key: props.index }));
                                        dispatch(viewActions.changeElement({ target: 'Type', value: e.target.value, key: props.index }));
                                        //dispatch(viewActions.changeElement({target : 'DefaultValue', value : e.target.text, key: props.index}))
                                    }else if (e.target.value === 'explorer') {
                                        dispatch(viewActions.changeElement({ target: 'Elementtype', value: 'explorer', key: props.index }));
                                        dispatch(viewActions.changeElement({ target: 'Type', value: e.target.value, key: props.index }));
                                        //dispatch(viewActions.changeElement({target : 'DefaultValue', value : e.target.text, key: props.index}))
                                    } else {
                                        dispatch(viewActions.changeElement({ target: 'Elementtype', value: 'text', key: props.index }));
                                        dispatch(viewActions.changeElement({ target: 'Type', value: e.target.value, key: props.index }));
                                    }
                                }}>
                                <option value="p">Text</option>
                                <option value="h2">H2</option>
                                <option value="h3">H3</option>
                                <option value="h4">H4</option>
                                <option value="h5">H5</option>
                                <option value="quicklink">Quicklink</option>
                                <option value="explorer">Explorer</option>
                            </select>

                            {curElement.Type === 'quicklink' && (
                                <>
                                    <h5>Auswahl</h5>
                                    <select
                                        name="tag-selection"
                                        value={curElement.Link ?? '/new/Contact'}
                                        onChange={e => {
                                            let index = e.target.selectedIndex;
                                            dispatch(viewActions.changeElement({ target: 'Link', value: e.target.value, key: props.index }));
                                            dispatch(viewActions.changeElement({ target: 'DefaultValue', value: (e.target[index] as any).text, key: props.index }));
                                        }}>
                                        {Object.keys(Quicklinks).map(key => {
                                            return <option value={key}>{window.Dictionary.getTranslation(Quicklinks[key].Text)}</option>;
                                        })}
                                    </select>
                                </>
                            )}
                            {curElement.Type === 'explorer' && (
                                <>
                                    <h5>Auswahl</h5>
                                    <select
                                        name="tag-selection"
                                        value={curElement.Link}
                                        onChange={(e: any) => {
                                            dispatch(viewActions.changeElement({ target: 'Link', value: e.target.value, key: props.index }));
                                            dispatch(viewActions.changeElement({ target: 'DefaultValue', value: e.target[e.target.selectedIndex]?.text, key: props.index }));
                                        }}>
                                        <option></option>
                                        {Object.entries(Explorers).map((key: any) => {
                                            console.log(key);
                                            return <option value={key[1].Type}>{window.Dictionary.getTranslation(key[1].Label)}</option>;
                                        })}
                                    </select>
                                </>
                            )}
                            {curElement.Type !== 'quicklink' && curElement.Type !== 'explorer' && (
                                <>
                                    <h5>Text</h5>
                                    <textarea onChange={e => dispatch(viewActions.changeElement({ target: 'DefaultValue', value: e.target.value, key: props.index }))}>{curElement ? curElement.Label : 14919}</textarea>
                                </>
                            )}
                            <button className="mfx-button button-border" onClick={e => dispatch(viewActions.removeElement(props.index))}>
                                {window.Dictionary.getTranslation(14880)}
                            </button>
                        </div>
                    )}
                    <div className="mfx-settings-accordion">
                        <h3 className={settingsSelected.elementgroup ? 'active' : ''} onClick={() => showSettings('elementgroup')}>
                            {window.Dictionary.getTranslation(14876)}
                        </h3>
                        {settingsSelected.elementgroup && props.index?.groupKey >= 0 && (
                            <>
                                <label> {window.Dictionary.getTranslation(6254)}:</label>
                                <select defaultValue={(props.data as any).CustomElements[props.index.groupKey ?? 0].Groupcol} onChange={e => changeElementGroup(e.target.value, 'Groupcol')}>
                                    {/*<option value={1}>12</option>
                                    <option value={2}>6</option>*/}
                                    <option value={3}>4</option>
                                    <option value={4}>3</option>
                                    <option value={6}>2</option>
                                    <option value={12}>1</option>
                                </select>

                                <Button onClickHandler={removeElementGroup} iconClass="icon-minus" dll="14877" />
                            </>
                        )}
                        {settingsSelected.elementgroup && !props.index?.groupKey && props.index?.groupKey !== 0 && <label>{window.Dictionary.getTranslation(14878)}</label>}
                    </div>
                </>
            )}
        </div>
    );
}
