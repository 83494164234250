/* eslint-disable jsx-a11y/label-has-associated-control */
import loadJsonData from 'Components/Lib/Utils/loadJsonData';
import { useEffect, useState } from 'react';
import CreatableSelect from 'react-select/creatable';
import URLS from 'Components/Lib/Utils/URLs.API';
import LocalStorage from 'Helpers/MFXLocalStorage';
import IconMenu from '../Layouts/IconMenu';
import IconPicker from './IconPicker';

export const TimeRecordingTypeDesigner = props => {
    const [workingTypes, setWorkingTypes] = useState(null);
    //const [options, setValues] = useState([]);
    const [currentOption, setCurrentOption] = useState(null);
    useEffect(() => {
        loadJsonData('/' + LocalStorage.getConnectionKey() + URLS.GetDropdownValues + 'WorkingTimeType').then(data => {
            console.log(data);
            if (data.Meta.Success && data.Data) {
                setWorkingTypes(data.Data);
            }
        });
    }, []);
    console.log(currentOption);
    return (
        <>
            <label>Type Options</label>
            <CreatableSelect
                onChange={newValue => {
                    var newVal = newValue.map(v => {
                        return { Label: v.label, Value: v.value, Icon: v.icon ?? '' };
                    });
                    console.log(newVal);
                    props.onChangeHandler(newVal);
                }}
                isMulti
                options={workingTypes?.map(type => {
                    return { label: type.Name, value: type.Value };
                })}
                menuPosition="fixed"
                value={
                    props.designData.Options &&
                    (props.designData.Options.map(option => {
                        return { label: option.Label, value: option.Value, icon: option.Icon };
                    }) ||
                        null)
                }
            />
            <label>Set Icon for</label>
            <select
                onChange={e => {
                    setCurrentOption(props.designData.Options.find(o => o.Value === e.target.value));
                }}
                value={currentOption?.Value}>
                    <option value={null}></option>
                {props.designData.Options?.map(type => {
                    return <option value={type.Value}>{type.Label}</option>;
                })}
            </select>
            {currentOption && (
                <IconPicker
                    icon={currentOption.Icon}
                    changeIcon={icon => {
                        var x = props.designData.Options.map(o => o.Value === currentOption.Value ? {Label: o.Label, Value: o.Value, Icon: icon} : o);
                        setCurrentOption({...currentOption,Icon:icon});
                        props.onChangeHandler(x);

                    }}
                />
            )}
        </>
    );
};

export default TimeRecordingTypeDesigner;
