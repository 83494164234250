import { useEffect, useState } from 'react';
import Loading from '../Elements/Loading';
import loadJsonData from '../Utils/loadJsonData';
import URLS from '../Utils/URLs.API';
import LocalStorage from 'Helpers/MFXLocalStorage';
import { formatDate } from 'Helpers/dateHelper';
import { useSearchParams } from 'react-router-dom';
import apiPost from '../Hooks/apiPost';
import loadJsonDelete from '../Utils/jsonDelete';

function Explorer(props) {
    //true for debugging
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [viewType, setViewType] = useState('list');
    const [selected, setSelected] = useState([]);
    const [searchParams, setSearchParams] = useSearchParams();
    const index = searchParams.get('index');
    const process = searchParams.get('process');
    const customer = searchParams.get('customer');
    const [expenseReport, setExpenseReport] = useState(searchParams.get('expensereport'));
    const [selectMode, setSelectMode] = useState(false);

    var _wr = function (type) {
        var orig = window.history[type];
        return function () {
            var rv = orig.apply(this, arguments);
            var e = new Event(type);
            window.dispatchEvent(e);
            return rv;
        };
    };
    window.history.replaceState = _wr('replaceState');

    window.addEventListener('replaceState', function (e) {
        var params = window.location.href.split('?')[1];
        if (params) {
            params = params.split('&').filter(p => p.includes('expensereport='));
            if (params && params[0].split('=')[1] !== expenseReport) setExpenseReport(params[0].split('=')[1]);
        }
    });

    useEffect(() => {
        loadData();
    }, [expenseReport]);
    function loadData() {
        setLoading(true);

        var inx = index ?? 0;
        var number = 0;
        if (props.designData.Link === 'CrmProcess') inx = process ?? 0;
        if (props.designData.Link === 'Customer') {
            inx = 0;
            number = customer ?? LocalStorage?.getTabData()?.primaryId ?? 0;
        }
        if (props.designData.Link === 'ExpenseReport') inx = expenseReport ?? 0;

        loadJsonData('/' + LocalStorage.getConnectionKey() + URLS.GetDocumentAttachments + '?Data.DocumentType=' + props.designData.Link + (inx ? '&Data.DocumentIndex=' + inx : '') + (number ? '&Data.DocumentNumber=' + number : '')).then(data => {
            if (data.Meta.Success && data) {
                setData(data.Data);
            }
            setLoading(false);
        });
    }
    function selectFile(e, inx) {
        e.preventDefault();
        if (selected.includes(inx)) {
            setSelected(prev => prev.filter(i => i !== inx));
        } else {
            setSelected(prev => {
                return [...prev, inx];
            });
        }
    }

    useEffect(() => {
        setSelected([]);
    }, [viewType]);

    function onChange(event) {
        console.log(event.target.files);
        if (event.target?.files?.length <= 0) return;
        const fileReader = new FileReader();
        fileReader.readAsDataURL(event.target.files[0]);
        fileReader.onload = e => {
            setLoading(true);
            console.log(e);
            console.log(e?.target?.result);
            if (e?.target?.result) {
                var x = e?.target?.result.split(';base64,');

                var inx = index ?? null;
                var number = null;
                if (props.designData.Link === 'CrmProcess') inx = process ?? null;
                if (props.designData.Link === 'Customer') {
                    inx = null;
                    number = customer ?? LocalStorage?.getTabData()?.primaryId ?? null;
                }
                if (props.designData.Link === 'ExpenseReport') inx = expenseReport ?? null;

                var body = { Data: { DocumentType: props.designData.Link, DocumentIndex: inx, DocumentNumber: number, Content: { FileContentBase64: x[1] ?? '', Filename: event.target.files[0].name } } };
                console.log(body);
                apiPost('/' + LocalStorage?.getConnectionKey() + URLS.CreateDocumentAttachment, body)
                    .then(data => {
                        console.log(data);
                        if (data.CreateStatus === 1) {
                            console.log('success');
                        } else {
                            console.log(data);
                        }
                        loadData();
                    })
                    .catch(err => {
                        setLoading(false);
                    });
            }
        };
    }

    function deleteFiles(all = false) {
        setLoading(true);
        selected.forEach(inx => {
            loadJsonDelete('/' + LocalStorage?.getConnectionKey() + URLS.DeleteDocumentAttachment + '?Index=' + data[inx].ID).then(data => {
                console.log(data);
                loadData();
                setLoading(false);
            });
        });
        if (all) {
            data.forEach(d => {
                loadJsonDelete('/' + LocalStorage?.getConnectionKey() + URLS.DeleteDocumentAttachment + '?Index=' + d.ID).then(data => {
                    console.log(data);
                    loadData();
                    setLoading(false);
                });
            });
        }
        setSelected([]);
    }

    function handleOnClick(e, inx) {
        if (selectMode) {
            selectFile(e, inx);
        } else {
            var a = document.createElement('a');
            console.log(data[inx].Content.FileData);
            a.href = 'data:application/octet-stream;base64,' + data[inx].Content.FileData;
            a.download = data[inx].FileName;
            a.click();
        }
    }

    return (
        <>
            <div className="mfx-action-buttons">
                <button className="mfx-button mfx-button-type-icon" onClick={e => loadData()}>
                    <i className="icon-refresh"></i>
                </button>
                <div className="explorer-dropdown">
                    <button className="mfx-button mfx-button-type-icon">
                        <i className="icon-add"></i>
                    </button>
                    <div>
                        <a>
                            <div className="custom-file-upload">
                                <label for="file-upload" style={{ margin: '0' }}>
                                    {window.Dictionary.getTranslation(15235)}
                                </label>
                                <input id="camera" type="file" accept="image/*" capture="camera" onChange={e => onChange(e)} />
                            </div>
                        </a>
                        <a>
                            <div className="custom-file-upload">
                                <label for="file-upload" style={{ margin: '0' }}>
                                    {window.Dictionary.getTranslation(16275)}
                                </label>

                                <input id="file-upload" type="file" onChange={e => onChange(e)} />
                            </div>
                        </a>
                    </div>
                </div>
                <div className="explorer-dropdown">
                    <button className="mfx-button mfx-button-type-icon">
                        <i className="icon-delete"></i>
                    </button>
                    <div>
                        {!selectMode && <a onClick={e => setSelectMode(true)}>{window.Dictionary.getTranslation(14810)}</a>}
                        {selectMode && (
                            <>
                                <a
                                    onClick={e => {
                                        setSelectMode(false);
                                        deleteFiles();
                                    }}>
                                    {window.Dictionary.getTranslation(15509)}
                                </a>
                                <a
                                    onClick={e => {
                                        setSelectMode(false);
                                        setSelected([]);
                                    }}>
                                    {window.Dictionary.getTranslation(16470)}
                                </a>
                            </>
                        )}
                        <a onClick={e => deleteFiles(true)}>{window.Dictionary.getTranslation(7288)}</a>
                    </div>
                </div>
                <div className="explorer-dropdown">
                    <button className="mfx-button mfx-button-type-icon">
                        <i className="icon-dots"></i>
                    </button>
                    <div>
                        <a onClick={e => setViewType('bigsymbols')}>{window.Dictionary.getTranslation(3848)}</a>
                        <a onClick={e => setViewType('smallsymbols')}>{window.Dictionary.getTranslation(3849)}</a>
                        <a onClick={e => setViewType('list')}>{window.Dictionary.getTranslation(818)}</a>
                        <a onClick={e => setViewType('detail')}>{window.Dictionary.getTranslation(1401)}</a>
                    </div>
                </div>
            </div>
            {loading && <Loading title="UserView" />}

            {!loading && data && (
                <div className="mfx-table-container">
                    <table className="explorer-table">
                        {viewType === 'detail' && (
                            <tr>
                                <th className="th-left"> {window.Dictionary.getTranslation(88)}</th>
                                <th className="th-left"> {window.Dictionary.getTranslation(1846)}</th>
                                <th className="th-left"> {window.Dictionary.getTranslation(1523)}</th>
                                <th className="th-left"> {window.Dictionary.getTranslation(3852)}</th>
                                <th className="th-left"> {window.Dictionary.getTranslation(206)}</th>
                            </tr>
                        )}
                        {data.map((file, inx) => {
                            switch (viewType) {
                                case 'bigsymbols':
                                    return (
                                        <td className={selected.includes(inx) ? 'active' : ''} onClick={e => handleOnClick(e, inx)}>
                                            <i className="icon-e-invoice big-icon"></i>
                                            {file.FileName}
                                        </td>
                                    );
                                case 'smallsymbols':
                                    return (
                                        <td className={selected.includes(inx) ? 'active' : ''} onClick={e => handleOnClick(e, inx)}>
                                            <i className="icon-e-invoice small-icon"></i>
                                            {file.FileName}
                                        </td>
                                    );
                                case 'detail':
                                    return (
                                        <tr className={selected.includes(inx) ? 'active' : ''} onClick={e => handleOnClick(e, inx)}>
                                            <td>{file.FileName}</td>
                                            <td>{file.FullPath}</td>
                                            <td>{file.FileName}</td>
                                            <td>{file.Size} Bytes</td>
                                            <td>{formatDate(new Date(file.CreateDate))}</td>
                                        </tr>
                                    );
                                default:
                                    return (
                                        <tr className={selected.includes(inx) ? 'active' : ''} onClick={e => handleOnClick(e, inx)}>
                                            <td>{file.FileName}</td>
                                        </tr>
                                    );
                            }
                        })}
                    </table>
                </div>
            )}
            {!loading && !data && (
                <h4 className="nothing-found">
                    {window.Dictionary.getTranslation(5484)} <i className="icon-search"></i>
                </h4>
            )}
        </>
    );
}

export default Explorer;
