import ModalPopup from '../Common/ModalPopup';
import sessionedLink from '../Utils/sessionedLinks';

/**
 * Renders a SavedModal
 * @returns {JSX.Element} The styled Link.
 * @param {any} props given by caller
 */

export default function QuestionModal(props) {
    return (
        <ModalPopup closeIcon={true} closePopup={props.handleClose}>
            <div className={'success-false'}>
                <div className="save-message">
                    <p dangerouslySetInnerHTML={{ __html: window.Dictionary.getTranslation(props.question) }} />
                </div>
                <div className='save-modal-buttons'>

                <button className="mfx-button" id="mfx-button-yes" onClick={() => props.handleYes()}>
                    {window.Dictionary.getTranslation(1651)}
                </button>
                    <button className="mfx-button" id="mfx-button-no" onClick={() => props.handleNo()}>
                    {window.Dictionary.getTranslation(1652)}
                </button>
                </div>
            </div>
        </ModalPopup>
    );
}
